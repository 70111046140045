import { initializeApp } from "firebase/app";
import {getAuth} from 'firebase/auth';
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import { getStorage } from "firebase/storage";

//Firebase configuration for things like Authentication, Database for user documents and answers then storage for any files uploaded.
const firebaseConfig = {
  apiKey: "AIzaSyCS6NyURwKv0LCVvjEIjpkbeBr2kC79Ty0",
  authDomain: "threatai-prod.firebaseapp.com",
  projectId: "threatai-prod",
  storageBucket: "threatai-prod.appspot.com",
  messagingSenderId: "227174042296",
  appId: "1:227174042296:web:1d338cb4313b036076c24d",
  measurementId: "G-Z663R2DS9B"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const st = getStorage(app);
export const addAnsToFirebase = async (data) => {
  try {
    // Add a new document with a generated ID to the "submissions" collection
    const docRef = await addDoc(collection(db, "submissions"), data);
    console.log("Document written with ID: ", docRef.id);
  } catch (e) {
    console.error("Error adding document: ", e);
    alert("Error adding document: ", e);
  }
};


export const auth = getAuth(app);
export { app, db, st };
