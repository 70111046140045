import React from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import Opening from "./opening/opening";
import SignIn from "./Auth/SignIn";
import SignUp from "./Auth/SignUp";
import Sidebar from "./sidebar/sidebar";
import ForgotPassword from "./AccountManagement/ForgotPass";
import AccountManage from "./AccountManagement/AccountManage";
import { ProtectedRoutes } from "./Auth/ProtectedRoutes";
import { onAuthStateChanged } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { auth } from './Firebase_Config/firebase';
import Dashboard from "./dash/Dashboard";
import CohereInterface from "./AIChat/AiTextGen";
import ThreatAssessment from "./Asessment/assessment";
import ThreatIntel from "./ThreatIntelligence/ThreatIntelligence";
import AiDatabase from "./AiDatabase/AiDatabase";

function App() {
  return (
    <Router>
      <Main />
    </Router>
  );
}

function Main() {
  const [user, setUser] =useState(null);
  const [isFetching, setIsFetching] = useState(true);
  const location = useLocation();
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if(user) {
        setUser(user)
        setIsFetching(false)
        return;
      }
      setUser(null);
      setIsFetching(false)
    })
    return () => unsubscribe();
  }, [])

  if(isFetching){
    return <div style={{
      backgroundImage: `linear-gradient(195deg, rgba(66, 66, 74, 0.6), rgba(25, 25, 25, 0.6)), url('bg.jpeg')`,
      backgroundSize: 'cover',
      backgroundPosition: 'center center'}}></div>
  }
  
  
  // Conditionally render the sidebar only if not on the landing page
  const isLandingPage = location.pathname === "/";
  const isSignIn = location.pathname === "/SignIn";
  const isSignUp = location.pathname === "/SignUp";
  const isForgot = location.pathname === "/ForgotPassword";
  
  // All of the routes for the different pages used in the web browers
  return (
    <div className="app-container">
      {!isLandingPage && !isSignUp && !isSignIn && !isForgot && <Sidebar user={user} />}
      <div className={isLandingPage ? "no-sidebar-content" : "content"}>
        <Routes>
          <Route path="/" element={<Opening  />} />
          <Route path="/dashboard" element={<ProtectedRoutes user={user}><Dashboard/></ProtectedRoutes>}/>
          <Route path="/SignIn" element={<SignIn user={user} />} />
          <Route path="/SignUp" element={<SignUp user={user} />} />
          <Route path='/ForgotPassword' element={<ForgotPassword user={user}/>}/>
          <Route path='/AccountManagement' element={<ProtectedRoutes user={user}><AccountManage/></ProtectedRoutes>}/>
          <Route path='/CoPilot' element={<ProtectedRoutes user={user}><CohereInterface/></ProtectedRoutes>}/>
          <Route path='/ThreatIntelligence' element={<ProtectedRoutes user={user}><ThreatIntel/></ProtectedRoutes>}/>
          <Route path='/Assessment' element={<ProtectedRoutes user={user}><ThreatAssessment/></ProtectedRoutes>}/>
          <Route path='/AiDatabase' element={<ProtectedRoutes user={user}><AiDatabase/></ProtectedRoutes>}/>
        </Routes>
      </div>
    </div>
  );
}

export default App;
