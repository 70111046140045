import React, { useState, useEffect } from 'react';
import './assessment.css'; // You can style the components here
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { MdOutlineCenterFocusWeak } from "react-icons/md";
import {  LuTarget } from "react-icons/lu";
import Alert from '@mui/material/Alert';
import { Navigate } from 'react-router-dom';
import { db, auth, st} from '../Firebase_Config/firebase';
import { collection, addDoc, getDoc, query, where, orderBy, limit,  doc, updateDoc, setDoc, getDocs } from 'firebase/firestore';
import { getStorage, ref, listAll, deleteObject, uploadBytes, getDownloadURL  } from 'firebase/storage';
import axios from 'axios';

//Function Save the answers to firebase 
const saveAnswersToFirebase = async (answers, userId) => {
  try {
    const userDocRef = doc(db, 'Answers', userId);
    // Combine answers with formattedAnswers into a single object
    const formattedAnswers = {
      ...answers, // Spread the existing answers
    };
    
    await setDoc(userDocRef, formattedAnswers, { merge: true }); // Merge updates into the existing document
    console.log('Answers saved to Firebase.');
  } catch (error) {
    console.error('Error saving answers to Firebase:', error);
  }
};

// Function to load answers from Firebase
const loadUserAnswersFromFirebase = async (userId) => {
  try {
    const userDocRef = doc(db, 'Answers', userId);
    const userAnswers = await getDoc(userDocRef);
    
    if (userAnswers.exists()) {
      return userAnswers.data(); // Return the saved answers
    } else {
      console.log('No answers found.');
      return {}; // Return an empty object if no documents are found
    }
  } catch (error) {
    console.error('Error loading answers from Firebase:', error);
    return {}; // Return an empty object in case of error
  }
};

// Collapsible Component
const Collapsible = ({
  summaryText,
  children,
  onAnswerChange,
  questionIndex,
  currentAnswer,
  groupTitle,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  

  const handleChange = (e) => {
    onAnswerChange(groupTitle, questionIndex, e.target.value);
  };
  

  return (
    <div className="collapsible">
      <div className="collapsible-header-2nd">
        <div className="arrow" onClick={() => setIsOpen(!isOpen)}>
          <LuTarget className='AIicons'/>
          {isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
          <span>{summaryText}</span>
        </div>
        <div className="collapsible-status">
          <form >
            <label>
              <input
                type="radio"
                value="Yes"
                checked={currentAnswer === 'Yes'}
                onChange={handleChange}
              />
              <span className="radioText">Yes</span>
            </label>
            <label>
              <input
                type="radio"
                value="No"
                checked={currentAnswer === 'No'}
                onChange={handleChange}
              />
              <span className="radioText">No</span>
            </label>
          </form>
        </div>
      </div>

      {isOpen && <div className="collapsible-content">{children}</div>}
    </div>
  );
};
// CollapsibleTitle Component
const CollapsibleTitle = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="collapsible">
      <div className="collapsible-header">
        <div className='arrow' onClick={() => setIsOpen(!isOpen)}>
          <MdOutlineCenterFocusWeak className='AIicon2'/>
          {isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
          <span>{title}</span>
        </div>
      </div>

      {isOpen && (
        <div className="collapsible-content">
          {React.Children.map(children, (child, index) =>
            React.cloneElement(child, {
              
            })
          )}
        </div>
      )}
    </div>

  );
};

// Main Component
const ThreatAssessment = () => {

  const group1= 'Techniques and Processes'
  const group2='Accessibility'
  const group3= 'Identifiability and Linkability'
  const group4= 'Security'
  const group5= 'Safety'
  const group6= 'Unawareness'
  const group7= 'Ethics and Human Rights'
  const group8= 'Non-Compliance'

  const questions = [
    {groupTitle: group1, text:'Is the task or assignment completely clear?', 
      explain:`
        <ul>
          <li>Is the problem you want to solve well defined?</li>
          <li>Are the possible benefits clear?</li>
        </ul>`, threatIf:'No', 
      recommendation:`
      <ul>
        <li>Clearly define the problem and outcome you are optimizing for.</li>
        <li>Assess if your AI system will be well-suited for this purpose.</li>
        <li>Always discuss if there are alternative ways to solve the problem.</li>
        <li>Define success! Working with individuals who may be directly affected can help you identify an appropriate way to measure success.</li>
        <li>Make sure there is a stakeholder involved (product owner for instance) with enough knowledge of the business and a clear vision about what the model needs to do.</li>
        <li>Did you try analytics first? In this context analytics could also offer inspiring views that can help you decide on the next steps. They can be a good source of information and are sometimes enough to solve the problem without the need of AI/ML.</li>
      </ul>`},
    {groupTitle: group1, text:'Can we assure that the data that we need is complete and trustworthy?', explain:'Can you avoid the known principle of “garbage in, garbage out”? Your AI system is only as reliable as the data it works with.', threatIf:'No', 
      recommendation:`
      <ul>
        <li>Verify the data sources:</li>
        <li>Is there information missing within the dataset?</li>
        <li>Are all the necessary classes represented?</li>
        <li>Does the data belong to the correct time frame and geographical coverage?</li>
        <li>Evaluate which extra data you need to collect/receive.</li>
        <li>Carefully consider representation schemes, especially in cases of text, video, APIs, and sensors. Text representation schemes are not all the same. If your system is counting on ASCII and it gets Unicode, will your system recognize the incorrect encoding? Source: [BerryVilleiML](https://berryvilleiml.com/interactive/)</li>
      </ul>`},
    {groupTitle: group1, text:'Can the data be representative of the different groups/populations?', 
      explain:`
      <ul>
        <li>It is important to reduce the risk of bias and different types of discrimination. Did you consider diversity and representativeness of users/individuals in the data?</li>
        <li>When applying statistical generalisation, the risk exists of making inferences due to misrepresentation, for instance: a postal code where mostly young families live can discriminate the few old families living there because they are not properly represented in the group</li>
      </ul>`, threatIf:'No', 
      recommendation:`
      <ul>
        <li>Who is covered and who is underrepresented?</li>
        <li>Prevent disparate impact: when the output of a member of a minority group is disparate compared to representation of the group. Consider measuring the accuracy from minority classes too instead of measuring only the total accuracy. Adjusting the weighting factors to avoid disparate impact can result in positive discrimination which has also its own issues: disparate treatment.</li>
        <li>
          One approach to addressing the problem of class imbalance is to randomly resample the training dataset. This technique can help to rebalance the class distribution when classes are under or over represented:
          <ul>
            <li>random oversampling (i.e. duplicating samples from the minority class)</li>
            <li>random undersampling (i.e. deleting samples from the majority class)</li>
          </ul>
        </li>
        <li>There are trade-offs when determining an AI system’s metrics for success. It is important to balance performance metrics against the risk of negatively impacting vulnerable populations.</li>
        <li>When using techniques like statistical generalisation is important to know your data well, and get familiarised with who is and who is not represented in the samples. Check the samples for expectations that can be easily verified. For example, if half the population is known to be female, then you can check if approximately half the sample is female.</li>
      </ul>`},
    {groupTitle: group1, text:'Have we identified all the important stakeholders needed in this phase of the project?', 
      explain:`
      <ul>
        <li>Do you have all the necessary stakeholders on board? Not having the right people that can give the necessary input can put the design of the AI system in danger.</li>
        <li>Think for instance when attributes or variables need to be selected, or when you need to understand the different data contexts.</li>
        <li>Data scientists should not be the only ones making assumptions about variables, it should really be a team effort.</li>
      </ul>`, threatIf:'No', 
      recommendation:`
      <ul>
        <li>Identify and involve on time the people that you need during the whole life cycle of the AI system. This will avoid unnecessary rework and frustrations.</li>
        <li>Identifying who’s responsible for making the decisions and how much control they have over the decision-making process allows for a more evident tracking of responsibility in the AI’s development process.</li>
      </ul>`},
    {groupTitle: group1, text:'Does the model need to be explainable for the users or affected persons?', explain:'Do you need to be able to give a clear explanation to the user about the logic that the AI system used to reach a certain decision? And can that decision have a big impact on the user?', threatIf:'Yes', 
      recommendation:`
      <ul>
        <li>Evaluate the type of models that you could use to solve the problem as specified in your task.</li>
        <li>Consider what the impact is if certain black box models cannot be used and interpretability tools do not offer sufficient results. You might need to evaluate a possible change in strategy.</li>
        <li>Data scientists can evaluate the impact from a technical perspective and discuss this with the rest of stakeholders. The decision keeps being a team effort.</li>
      </ul>`},
    {groupTitle: group1, text:'Are we preventing Data Leakage?', explain:`Data Leakage is present when your features contain information that your model should not legitimately be allowed to use, leading to overestimation of the model's performance.`, threatIf:'No', 
      recommendation:`
      <ul>
        <li>Avoid using proxies for the outcome variable as a feature.</li>
        <li>Do not use the entire data set for imputations, data-based transformations or feature selection.</li>
        <li>Avoid doing standard k-fold cross-validation when you have temporal data.</li>
        <li>Avoid using data that happened before model training time but is not available until later. This is common where there is delay in data collection.</li>
        <li>Do not use data in the training set based on information from the future: if X happened after Y, you shouldn’t build a model that uses X to predict Y.</li>
      </ul>`},
    {groupTitle: group1, text:'Are we preventing Concept and Data Drift?', 
      explain:`
      <ul>
        <li>Data Drift weakens performance because the model receives data on which it hasn’t been trained.</li>
        <li>With Concept Drift the statistical properties of the target variable, which the model is trying to predict, change over time in unforeseen ways causing accuracy issues.</li>
      </ul>`, threatIf:'No', 
      recommendation:`
      <ul>
        <li>Select an appropriate drift detection algorithm and apply it separately to labels, model’s predictions and data features.</li>
        <li>Incorporate monitoring mechanisms to detect potential errors early.</li>
      </ul>`},
    {groupTitle: group1, text:'Once our model is running, can we keep feeding it data?', 
      explain:`
      <ul>
        <li>Will you use the output from other models to feed the model again (looping)? or will you use other sources ?</li>
        <li>Are you sure this data will be continuously available?</li>
      </ul>`, threatIf:'No', 
      recommendation:`
      <ul>
        <li>Consider how the model will keep learning. Design a strategy to prevent issues with the next steps.</li>
        <li>Imagine you planned to feed your model with input obtained by mining surveys and it appears these surveys contain a lot of free text fields. To prepare that data and avoid issues (bias, inaccuracies, etc) you might need extra time. Consider these type of scenarios that could impact the whole life cycle of your product!</li>
      </ul>`},
    {groupTitle: group1, text:'Is human intervention necessary to oversee the automatic decision making (ADM) process of the AI system?', 
      explain:`
      <ul>
        <li>Do humans need to review the process and the decisions of the AI system? Consider the impact that this could have for the organisation.</li>
        <li>Do you have enough capacitated employees available for this role?</li>
      </ul>
      `, threatIf:'Yes', 
      recommendation: 'It is important that people are available for this role and that they receive specific training on how to exercise oversight. The training should teach them how to perform the oversight without being biased by the decision of the AI system (automation bias).' },
    {groupTitle: group1, text:'Could the channels that we will use to collect real-time input data fail?', 
      explain:`
      <ul>
        <li>Are these channels trustworthy?</li>
        <li>What will happen in case of failure?</li>
        <li>Think for instance about IoT devices used as sensors.</li>
      </ul>`, threatIf:'Yes', 
      recommendation:`
      <ul>
        <li>If you are collecting/receiving data from sensors, consider estimating the impact it could have on your model if any of the sensors fail and your input data gets interrupted or corrupted.</li>
        <li>Sensor blinding attacks are one example of a risk faced by poorly designed input gathering systems. Note that consistent feature identification related to sensors is likely to require human calibration. Source: [BerryVilleiML](https://berryvilleiml.com/)</li>
      </ul>`},
    {groupTitle: group1, text:'When datasets from external sources are updated, can we receive and process the new data on time?', 
      explain:`
      <ul>
        <li>This could be especially risky in health and finance environments. How much change are you expecting in the data you receive?</li>
        <li>How can you make sure that you receive the updates on time?</li>
      </ul>
      `, threatIf:'No', recommendation:'Not only do you need to be able to trust the sources but you also need to design a process in which data is prepared on time to be used in the model and where you can timely consider the impact it could have in the output of the model, especially when this could have a negative impact on the users. This process can be designed once you know how often changes in the data can be expected and how big the changes are.' },
    {groupTitle: group1, text:'Can we confirm the legitimacy of the data sources that we need?', 
      explain:`
      <ul>
        <li>Data lineage can be necessary to demonstrate trust as part of your information transparency policy, but it can also be very important when it comes to assessing impact on the data flow. If sources are not verified and legitimised you could run risks such as data being wrongly labelled for instance.</li>
        <li>Do you know where you need to get the data from? Who is responsible for the collection, maintenance and dissemination? Are the sources verified? Do you have the right agreements in place? Are you allowed to receive or collect that data? Also keep ethical considerations in mind!</li>
      </ul>
      `, threatIf:'No', 
      recommendation:`
      <ul>
        <li>Develop a robust understanding of your relevant data feeds, flows and structures such that if any changes occur to the model data inputs, you can assess any potential impact on model performance. In case of third party AI systems contact your vendor to ask for this information.</li>
        <li>If you are using synthetic data you should know how it was created and the properties it has. Also keep in mind that synthetic data might not be the answer to all your privacy related problems; synthetic data does not always provide a better trade-off between privacy and utility than traditional anonymisation techniques.</li>
        <li>Do you need to share models and combine them? The usage of Model Cards and Datasheets can help providing the source information.</li>
      </ul>`},
    {groupTitle: group1, text:'Do we have enough dedicated resources to monitor the algorithm?', explain:'Do you already have a process in place to monitor the quality of the output and system errors? Do you have resources to do this? Not having the right process and resources in place could have an impact on the project deadline, the organisation and the users.', threatIf:'No', 
      recommendation:`
      <ul>
        <li>Put a well-defined process in place to monitor if the AI system is meeting the intended goals.</li>
        <li>Define failsafe fallback plans to address AI system errors of whatever origin and put governance procedures in place to trigger them.</li>
        <li>Put measure in places to continuously assess the quality of the output data: e.g. check that predictions scores are within expected ranges; anomaly detection in output and reassign input data leading to the detected anomaly.</li>
        <li>Does the data measure what you need to measure? You could get measurement errors if data is not correctly labelled.</li>
      </ul>`},
    {groupTitle: group1, text:'Can we collect all the data that we need for the purpose of the algorithm?', explain:'Could you face difficulties obtaining certain type of data? This could be due to different reasons such as legal, proprietary, financial, physical, technical, etc. This could put the whole project in danger.', threatIf:'No', recommendation:'In the early phases of the project (as soon as the task becomes more clear), start considering which raw data and types of datasets you might need. You might not have the definitive answer until you have tested the model, but it will already help to avoid extra delays and surprises. You might have to involve your legal and financial department. Remember that this is a team effort.' },
    //Group 2
    {groupTitle: group2, text:`Can our system's user interface be used by those with special needs or disabilities?`, 
        explain:`
        <ul>
            <li>Does your AI system need to be accessible and usable for users of assistive technologies (such as screen readers)?</li>
            <li>Is it possible to provide text alternatives for instance?</li>
        </ul>
        `, threatIf:'No', 
        recommendation:`
        <ul>
            <li>Implement Universal Design principles during every step of the planning and development process. This is not only important for web interfaces but also when AI systems/robots assist individuals.</li>
            <li>Test the accessibility of your design with different users (also with disabilities).</li>
        </ul>`},
    {groupTitle: group2, text:'Do we need to offer a redress mechanism to the users?', 
        explain:`
        <ul>
            <li>For applications that can adversely affect individuals, you might need to consider implementing a redress by design mechanism where affected individuals can request remedy or compensation.</li>
            <li>Article 22(3) GDPR provides individuals with a right to obtain human intervention if a decision is made solely by an AI system and it also provides the right to contest the decision.</li>
        </ul>
        `, threatIf:'Yes', 
        recommendation:`
        <ul>
            <li>Think about implementing mechanisms to effectively detect and rectify wrong decisions made by your system.</li>
            <li>Provide a mechanism to ignore or dismiss undesirable features or services.</li>
            <li>Wrong decisions could also have an impact on people that have not been the target of the data collection (data spillovers). Consider designing a way to offer all affected people the opportunity to contest the decisions of your system and request remedy or compensation. This mechanism should be easily accessible and it implies that you would need to have internally implemented a process where redress can be effectibily executed. This also has impact on the resources/skills needed to fulfil this process.</li>
            <li>Consider this a necessary step to ensure responsibility and accountability.</li>
        </ul>
        `},
    {groupTitle: group2, text:'Do we need to implement an age gate to use our product?', 
        explain:`
        <ul>
            <li>Is your product not meant to be used by children? You might need to implement an age verification mechanism to prevent children from accessing the product.</li>
            <li>Age verification can also be important to provide the right diagnosis (health sector).</li>
        </ul>
        `, threatIf:'Yes', 
        recommendation:`
        <ul>
            <li>Clearly specify in the user instructions for which age group the application is built. Labels or symbols can be very helpful.</li>
            <li>Consider which design is more appropriate based on your use case, and consider the possible risks associated with your design choice, and the mitigating measures you can implement to reduce that risk. Document the rest risks that you want to accept.</li>
            <li>Test the accessibility of your design with different age groups.</li>
        </ul>
        `},
    {groupTitle: group2, text:'If users need to provide consent, can we make the required information easily available?', 
        explain:`
        <ul>
            <li>Can the information be easily accessible and readable?</li>
            <li>Do you need to build a special place for it (think of a robot where you might need to have a screen for showing the text)</li>
        </ul>
        `, threatIf:'No', recommendation:`
        <ul>
            <li>As part of privacy compliance you need to provide clear information about the processing and the logic of the algorithm. This information should be easily readable and accessible. During the design phase consider when and how you are going to provide this information. Especially in robots using AI this could be a challenge.</li>
            <li>Comply with accessibility rules.</li>
        </ul>
        `},
    {groupTitle: group2, text:'Could the user perceive the message from the AI system in a different way than intended?', 
        explain:`
        <ul>
            <li>Is the perception of the provided information the same as the one intended?</li>
            <li>Explainability is critical for end-users in order to take informed and accountable actions.</li>
        </ul>
        `, threatIf:'Yes', recommendation:`
        <ul>
            <li>Understanding who is going to interact with the AI system can help to make the interaction more effective. Identify your different user groups.</li>
            <li>Involve communication experts and do enough user testing to reduce the gap between the intended and the perceived meaning.</li>
        </ul>
        `},
    {groupTitle: group2, text:'Could the learning curve of the product be an issue?', 
        explain:`
        <ul>
            <li>Does usage of the AI system require new (digital) skills?</li>
            <li>How quickly are users expected to learn how to use the product?</li>
            <li>Difficulties to learn how the system works could also bring the users in danger and have consequences for the reputation of the product or organisation.</li>
        </ul>
        `, threatIf:'Yes', 
        recommendation:`
        <ul>
            <li>You can also provide assistance, appropriate training material and disclaimers to users on how to adequately use the system.</li>
            <li>The words and language used in the interface, the complexity and lack of accessibility of some features could exclude people from using the application. Consider making changes in the design of the product where necessary.</li>
            <li>Consider this also when children are future users.</li>
        </ul>
        `},
    
    //Group 3
    {groupTitle: group3, text:'Can the data used to feed the model be linked to individuals?', explain:'Do you need to use unique identifiers in your training dataset? If personal data is not necessary for the model you would not really have a legal justification for using it.', threatIf:'Yes', 
        recommendation:`
        <ul>
            <li>Unique identifiers might be included in the training set when you want to be able to link the results to individuals. Consider using pseudo-identifiers or other techniques that can help you protect personal data.</li>
            <li>Document the measures you are taking to protect the data. Consider if your measures are necessary and proportional.</li>
        </ul>
        `},
    {groupTitle: group3, text:'Could actions be incorrectly attributed to an individual or group?', explain:'Your AI system could have an adverse impact on individuals by incorrectly attributing them facts or actions. For instance, a facial recognition system that identifies a person incorrectly, or an inaccurate risk prediction model that could negatively impact an individual.', threatIf:'Yes', 
        recommendation:`
        <ul>
            <li>Evaluate the possible consequences of inaccuracies of your AI system and implement measures to prevent these errors from happening: avoiding bias and discrimination during the life cycle of the model, ensuring the quality of the input data, implementing a strict human oversight process, ways to double check the results with extra evidence, implementing safety and redress mechanisms, etc.</li>
            <li>Assess the impact on the different human rights of the individual.</li>
            <li>Consider not to implement such a system if you cannot mitigate the risks.</li>
        </ul>
        `},
    {groupTitle: group3, text:'Could we be revealing information that a person has not chosen to share?', 
        explain:`
        <ul>
            <li>How can you make sure the product doesn’t inadvertently disclose sensitive or private information during use (e.g., indirectly inferring user locations or behaviour)?</li>
            <li>Could movements or actions be revealed through data aggregation?</li>
        </ul>
        ` , threatIf:'Yes', 
        recommendation:`
        <ul>
            <li>Be careful when making data public that you think is anonymised. Location data and routes can sometimes be de-anonymised (e.g. users of a running app disclosing location by showing heatmap).</li>
            <li>It is also important to offer privacy by default: offer the privacy settings by default at the maximum protection level. Let the users change the settings after having offered them clear information about the consequences of reducing the privacy levels.</li>
        </ul>
        `},
    //Group 4
    {groupTitle: group4, text:'Do we need to red-team/pen test the AI system?', explain:'Do you need to test the security of your AI system before it goes live? This could have an impact on your project deadlines.', threatIf:'Yes', recommendation:'Include the time you might need for a pen test in your project planning. Sometimes this can take weeks: you might have to hire an external party, agree on the scope, sign the corresponding agreements and even plan a retest.' },
    {groupTitle: group4, text:'Are our APIs securely implemented?', explain:`APIs connect computers or pieces of software to each other. APIs are common attack targets in security and are in some sense your public front door. They should not expose information about your system or model. Source: [BerryVilleiML](https://berryvilleiml.com/interactive/)`, threatIf:'No', 
        recommendation:`
        <ul>
            <li>Check how do you handle time and state and how is authentication implemented in your APIs.</li>
            <li>Make sure that sensitive information such us API calls secrets are not sent in your commands.</li>
            <li>Implement encryption at rest and in transit (TLS) and test often your APIs for vulnerabilities.</li>
        </ul>
        `},
    {groupTitle: group4, text:'Is our data storage protected?', explain:`Is your data stored and managed in a secure way? Think about training data, tables, models, etc. Are you the only one with access to your data sources? Source: [BerryVilleiML](https://berryvilleiml.com/interactive/)`, threatIf:'No', 
        recommendation:`
        <ul>
            <li>Implement access control rules.</li>
            <li>Verify the security of the authentication mechanism (and the system as a whole).</li>
            <li> Consider the risk when utilizing public/external data sources.</li>
        </ul>
        `},
    {groupTitle: group4, text:'If our AI system uses randomness, is the source of randomness properly protected?', explain:`Randomness plays an important role in stochastic systems. “Random” generation of dataset partitions may be at risk if the source of randomness is easy to control by an attacker interested in data poisoning. Source: [BerryVilleiML](https://berryvilleiml.com/interactive/)`, threatIf:'No', recommendation:`Use of cryptographic randomness sources is encouraged. When it comes to machine learning (ML), setting weights and thresholds “randomly” must be done with care. Many pseudo-random number generators (PRNG) are not suitable for use. PRNG loops can really damage system behaviour during learning. Cryptographic randomness directly intersects with ML when it comes to differential privacy. Using the wrong sort of random number generator can lead to subtle security problems. Source: [BerryVilleiML](https://berryvilleiml.com/interactive/)`},
    {groupTitle: group4, text:'Is our model suited for processing confidential information?', 
        explain:`
        <ul>
            <li>There are certain kinds of machine learning (ML) models which actually contain parts of the training data in its raw form within them by design. For example, ‘support vector machines’ (SVMs) and ‘k-nearest neighbours’ (KNN) models contain some of the training data in the model itself.</li>
            <li>Algorithmic leakage is an issue that should be considered carefully.</li>
        </ul>
        Source: [BerryVilleiML](https://berryvilleiml.com/interactive/)
        `, threatIf:'No', recommendation:'When selecting the algorithm perform analyses and test to rule out algorithmic leakage.' },
    {groupTitle: group4, text:'Can our AI system scale in performance from data input to data output?', explain:`Can your algorithm scale in performance from the data it learned on to real data? In online situations the rate at which data comes into the model may not align with the rate of anticipated data arrival. This can lead to both outright ML system failure and to a system that “chases" its own tail. Source: [BerryVilleiML](https://berryvilleiml.com/interactive/)`, threatIf:'No', 
        recommendation:`
        <ul>
            <li>Find out what the rate would be of expected data arrival to your model and perform tests in a similar environment with similar amount of data input.</li>
            <li>Implement measures to make your model scalable.</li>
        </ul>
        `},
    {groupTitle: group4, text:'Are we protected from insider threats?', explain:`AI designers and developers may deliberately expose data and models for a variety of reasons, e.g. revenge or extortion. Integrity, data confidentiality and trustworthiness are the main impacted security properties. Source: [ENISA](https://www.enisa.europa.eu/publications/artificial-intelligence-cybersecurity-challenges)`, threatIf:'No', 
        recommendation:`
        <ul>
            <li>Implement on and off boarding procedures to help guarantee the trustworthiness of your internal and external employees.</li>
            <li>Enforce separation of duties and least privilege principle.</li>
            <li>Enforce the usage of managed devices with appropriate policies and protective software.</li>
            <li>Awareness training.</li>
            <li>Implement strict access control and audit trail mechanisms.</li>
        </ul>
        `},
    {groupTitle: group4, text:'Are we protected against model sabotage?', explain:`Sabotaging the model is a nefarious threat that refers to exploitation or physical damage of libraries and machine learning platforms that host or supply AI/ML services and systems. Sources: [ENISA](https://www.enisa.europa.eu/publications/artificial-intelligence-cybersecurity-challenges)`, threatIf:'No', 
        recommendation:`
        <ul>
            <li>Implement security measures to protect your models against sabotage.</li>
            <li>Assess the security profile of third party tooling and providers.</li>
            <li>Consider implementing a disaster recovery plan with mitigation measures for this type of attack.</li>
        </ul>
        `},
    {groupTitle: group4, text:'Could there be possible malicious use, misuse or inappropriate use of our AI system?', explain:`An example of abusability: A product that is used to spread misinformation; for example, a chatbot being misused to spread fake news.`, threatIf:'Yes', 
        recommendation:`
        <ul>
            <li>Threat model your system: anticipate vulnerabilities and look for ways to hijack and weaponize your system for malicious activity.</li>
            <li>Conduct *red team* exercises.</li>
        </ul>
        `},
    {groupTitle: group4, text:'Could environmental phenomena or natural disasters have a negative impact on our AI system?', 
        explain:`
        <ul>
            <li>Examples of environmental phenomena are heating, cooling and climate change.</li>
            <li>Examples of natural disasters to take into account are earthquakes, floods and fires. Environmental phenomena may adversely influence the operation of IT infrastructure and hardware systems that support AI systems. Natural disasters may lead to unavailability or destruction of the IT infrastructures and hardware that enables the operation, deployment and maintenance of AI systems. Such outages may lead to delays in decision-making, delays in the processing of data streams and entire AI systems being placed offline. </li>
        </ul>
        Sources: [ENISA](https://www.enisa.europa.eu/publications/artificial-intelligence-cybersecurity-challenges)
        `, threatIf:'Yes', recommendation:'Implement a disaster discovery plan considering different scenarios, impact, Recovery Time Objective (RTO), Recovery Point Objective (RPO) and mitigation measures.' },
    {groupTitle: group4, text:'Are we protected from perturbation attacks?', 
        explain:`
        <ul>
            <li>In perturbation style attacks, the attacker stealthily modifies the query to get a desired response.</li>
            <li>Examples:
                <ul> 
                    <li>Image: Noise is added to an X-ray image, which makes the predictions go from normal scan to abnormal.</li>
                    <li>Text translation: Specific characters are manipulated to result in incorrect translation. The attack can suppress a specific word or can even remove the word completely. Source: Microsoft, Threat Modelling AI/ML Systems and Dependencies.</li>
                </ul>
            </li>
            <li> Random perturbation of labels is also a possible attack, while additionally there is the case of adversarial label noise (intentional switching of classification labels leading to deterministic noise, an error that the model cannot capture due to its generalization bias).</li>
        </ul>`, threatIf:'No', 
        recommendation:`
        <ul>
            <li>
                Reactive/Defensive Detection Actions:
                <ul>
                    <li>Implement a minimum time threshold between calls to the API providing classification results. This slows down multi-step attack testing by increasing the overall amount of time required to find a success perturbation.</li>
                </ul>
            </li>
            <li>
                Proactive/Protective Actions:
                <ul>
                    <li>Develop a new network architecture that increases adversarial robustness by performing feature denoising.</li>
                    <li>Train with known adversarial samples to build resilience and robustness against malicious inputs.</li>
                    <li>Invest in developing monotonic classification with selection of monotonic features. This ensures that the adversary will not be able to evade the classifier by simply padding features from the negative class.</li>
                    <li>Feature squeezing can be used to harden DNN models by detecting adversarial examples.</li>
                </ul>
            </li>
            <li>
                Response Actions:
                <ul>
                    <li>Issue alerts on classification results with high variance between classifiers, especially when from a single user or small group of users.</li>
                </ul>
            </li>
        </ul>
        Source: Microsoft, Threat Modelling AI/ML Systems and Dependencies.
        `},
    {groupTitle: group4, text:'Are we protected from poisoning attacks?', 
        explain:`
        <ul>
            <li>In a poisoning attack, the goal of the attacker is to contaminate the machine model generated in the training phase, so that predictions on new data will be modified in the testing phase. This attack could also be caused by insiders.</li>
            <li>Example: in a medical dataset where the goal is to predict the dosage of a medicine using demographic information, researchers introduced malicious samples at 8% poisoning rate, which changed the dosage by 75.06% for half of the patients. Source: Microsoft, Threat Modelling AI/ML Systems and Dependencies.</li>
            <li>
                Other scenarios:
                <ul>
                    <li>Data tampering: Actors like AI/ML designers and engineers can deliberately or unintentionally manipulate and expose data. Data can also be manipulated during the storage procedure and by means of some processes like feature selection. Besides interfering with model inference, this type of threat can also bring severe discriminatory issues by introducing bias. Source: [ENISA](https://www.enisa.europa.eu/publications/artificial-intelligence-cybersecurity-challenges)</li>
                    <li>An attacker who knows how a raw data filtration scheme is set up may be able to leverage that knowledge into malicious input later in system deployment. Source:[BerryVilleiML](https://berryvilleiml.com/interactive/)</li>
                    <li>Adversaries may fine-tune hyper-parameters and thus influence the AI system’s behaviour. Hyper-parameters can be a vector for accidental overfitting. In addition, hard to detect changes to hyper-parameters would make an ideal insider attack. Source: [ENISA](https://www.enisa.europa.eu/publications/artificial-intelligence-cybersecurity-challenges)</li>
                </ul>
            </li>
        </ul>`, threatIf:'No', 
        recommendation:`
            <ul>
                <li>Define anomaly sensors to look at data distribution on day to day basis and alert on variations.</li>
                <li>Measure training data variation on daily basis, telemetry for skew/drift.</li>
                <li>Input validation, both sanitization and integrity checking.</li>
                <li>Implement measures against insider threats.</li>
            </ul>
            Source: Microsoft, Threat Modelling AI/ML Systems and Dependencies.
        ` },
    {groupTitle: group4, text:'Are we protected from model inversion attacks?', 
        explain:`
        <ul>
            <li>In a model inversion attack, if attackers already have access to some personal data belonging to specific individuals included in the training data, they can infer further personal information about those same individuals by observing the inputs and outputs of the ML model.</li>
            <li>In model Inversion the private features used in machine learning models can be recovered. This includes reconstructing private training data that the attacker should not have access to.</li>
            <li>Example: an attacker recovers the secret features used in the model through careful queries.</li>
        </ul>
        Source: Microsoft, Threat Modelling AI/ML Systems and Dependencies.`, threatIf:'No', 
        recommendation:`
        <ul>
            <li>Interfaces to models trained with sensitive data need strong access control.</li>
            <li>Implement rate-limiting on the queries allowed by the model.</li>
            <li>Implement gates between users/callers and the actual model by performing input validation on all proposed queries, rejecting anything not meeting the model’s definition of input correctness and returning only the minimum amount of information needed to be useful.</li>
        </ul>
        Source: Microsoft, Threat Modelling AI/ML Systems and Dependencies.
        ` },
    {groupTitle: group4, text:'Are we protected from membership inference attacks?', 
        explain:`
        <ul>
            <li>In a membership inference attack, the attacker can determine whether a given data record was part of the model’s training dataset or not.</li>
            <li>Example: researchers were able to predict a patient’s main procedure (e.g.: Surgery the patient went through) based on the attributes (e.g.: age, gender, hospital).</li>
        </ul>
        Source: Microsoft, Threat Modelling AI/ML Systems and Dependencies.`, threatIf:'No', 
        recommendation:`
        <ul>
            <li>Some research papers indicate Differential Privacy would be an effective mitigation. Check for more information [Threat Modeling AI/ML Systems and Dependencies](https://docs.microsoft.com/en-us/security/engineering/threat-modeling-aiml).</li>
            <li>The usage of neuron dropout and model stacking can be effective mitigations to an extent. Using neuron dropout not only increases resilience of a neural net to this attack, but also increases model performance.</li>
        </ul>
        Source: Microsoft, Threat Modelling AI/ML Systems and Dependencies.
        ` },
    {groupTitle: group4, text:'Are we protected from model stealing attacks?', 
        explain:`
        <ul>
            <li>In model stealing, the attackers recreate the underlying model by legitimately querying the model. The functionality of the new model is the same as that of the underlying model.</li>
            <li>Example: in the BigML case, researchers were able to recover the model used to predict if someone should have a good/bad credit risk using 1,150 queries and within 10 minutes.</li>
        </ul>
        Source: Microsoft, Threat Modelling AI/ML Systems and Dependencies.`, threatIf:'No', 
        recommendation:`
        <ul>
            <li>Minimize or obfuscate the details returned in prediction APIs while still maintaining their usefulness to *honest* applications.</li>
            <li>Define a well-formed query for your model inputs and only return results in response to completed, well-formed inputs matching that format.</li>
            <li>Return rounded confidence values. Most legitimate callers do not need multiple decimal places of precision.</li>
        </ul>
        Source: Microsoft, Threat Modelling AI/ML Systems and Dependencies.
        ` },
    {groupTitle: group4, text:'Are we protected from reprogramming deep neural nets attacks?', 
        explain:`
        <ul>
            <li>By means of a specially crafted query from an adversary, Machine Learning systems can be reprogrammed to a task that deviates from the creator’s original intent.</li>
            <li>Example: ImageNet, a system used to classify one of several categories of images was repurposed to count squares.</li>
        </ul>
        Source: Microsoft, Threat Modelling AI/ML Systems and Dependencies.`, threatIf:'No', 
        recommendation:`
        <ul>
            <li>Configure a strong client-server mutual authentication and access control to model interfaces.</li>
            <li>Takedown of the offending accounts.</li>
            <li>Identify and enforce a service-level agreement for your APIs. Determine the acceptable time-to-fix for an issue once reported and ensure the issue no longer repros once SLA expires.</li>
        </ul>
        Source: Microsoft, Threat Modelling AI/ML Systems and Dependencies.
        ` },
    {groupTitle: group4, text:'Are we protected from adversarial example?', 
        explain:`
        <ul>
            <li>An adversarial example is an input/query from a malicious entity sent with the sole aim of misleading the machine learning system.</li>
            <li>Example: researchers constructed sunglasses with a design that could fool image recognition systems, which could no longer recognize the faces correctly.</li>
        </ul>
        Source: Microsoft, Threat Modelling AI/ML Systems and Dependencies.
        `, threatIf:'No', recommendation:`These attacks manifest themselves because issues in the machine learning layer were not mitigated. As with any other software, the layer below the target can always be attacked through traditional vectors. Because of this, traditional security practices are more important than ever, especially with the layer of unmitigated vulnerabilities (the data/algo layer) being used between AI and traditional software. Source: Microsoft, Threat Modelling AI/ML Systems and Dependencies.` },
    {groupTitle: group4, text:'Are we protected from malicious AI/ML providers who could recover training data?', 
        explain:`
        <ul>
            <li>Malicious ML providers could query the model used by a customer and recover this customer’s training data. The training process is either fully or partially outsourced to a malicious third party who wants to provide the user with a trained model that contains a backdoor.</li>
            <li>Example: researchers showed how a malicious provider presented a backdoored algorithm, wherein the private training data was recovered. They were able to reconstruct faces and texts, given the model alone.</li>
        </ul>
        Source: Microsoft, Threat Modelling AI/ML Systems and Dependencies.`, threatIf:'No', 
        recommendation:`
        <ul>
            <li>Research papers demonstrating the viability of this attack indicate Homomorphic Encryption could be an effective mitigation. Check for more information [Threat Modeling AI/ML Systems and Dependencies](https://docs.microsoft.com/en-us/security/engineering/threat-modeling-aiml)</li>
            <li>Train all sensitive models in-house.</li>
            <li>Catalog training data or ensure it comes from a trusted third party with strong security practices.</li>
            <li>Threat model the interaction between the MLaaS provider and your own systems.</li>
        </ul>
        Source: Microsoft, Threat Modelling AI/ML Systems and Dependencies.
        ` },
    {groupTitle: group4, text:'Are we protected from attacks to the AI/ML Supply Chain?', 
        explain:`
        <ul>
            <li>Owing to large resources (data + computation) required to train algorithms, the current practice is to reuse models trained by large corporations, and modify them slightly for the task at hand. These models are curated in a Model Zoo. In this attack, the adversary attacks the models hosted in the Model Zoo, thereby poisoning the well for anyone else.</li>
            <li>Example: researchers showed how it was possible for an attacker to insert malicious code into one of the popular models. An unsuspecting ML developer downloaded this model and used it as part of the image recognition system in their code.</li>
        </ul>
        Source: Microsoft, Threat Modelling AI/ML Systems and Dependencies.`, threatIf:'No', 
        recommendation:`
        <ul>
            <li>Minimize 3rd-party dependencies for models and data where possible.</li>
            <li>Incorporate these dependencies into your threat modelling process.</li>
            <li>Leverage strong authentication, access control and encryption between 1st/3rd-party systems.</li>
            <li>Perform integrity checks where possible to detect tampering.</li>
        </ul>
        Source: Microsoft, Threat Modelling AI/ML Systems and Dependencies.
        ` },
    {groupTitle: group4, text:'Are we protected from exploits on software dependencies of our AI/ML systems?', 
        explain:`
        <ul>
            <li>In this case, the attacker does NOT manipulate the algorithms, but instead exploits traditional software vulnerabilities such as buffer overflows or cross-site scripting.</li>
            <li>Example: an adversary customer finds a vulnerability in a common OSS dependency that you use and uploads a specially crafted training data payload to compromise your service.</li>
        </ul>
        Source: Microsoft, Threat Modelling AI/ML Systems and Dependencies.
        `, threatIf:'No', recommendation:`Work with your security team to follow applicable Security Development Lifecycle/Operational Security Assurance best practices. Source: Microsoft, Threat Modelling AI/ML Systems and Dependencies.`},
    //Group5
    {groupTitle: group5, text:'In case of system failure, could users be adversely impacted?', 
        explain:`
        <ul>
            <li>Do you have a mechanism implemented to stop the processing in case of harm?</li>
            <li>Do you have a way to identify and contact affected individuals and mitigate the adverse impacts?</li>
            <li>Imagine a scenario where your AI system, a care-robot, is taking care of an individual (the patient) by performing some specific tasks and that this individual depends on this care.</li>
        </ul>`, threatIf:'Yes', 
        recommendation:`
        <ul>
            <li>Implement some kind of *stop button* or procedure to safely abort an operation when needed.</li>
            <li>Establish a detection and response mechanism for undesirable adverse effects on individuals.</li>
            <li>Define criticality levels of the possible consequences of faults/misuse of the AI system: what type of harm could be caused to the individuals, environment or organisations?</li>
        </ul>
        ` },
    {groupTitle: group5, text:'Could our AI system have an adverse impact on the environment?', 
        explain:`
        <ul>
            <li>Ideally only models are used that do not demand the consumption of energy or natural resources beyond what is sustainable.</li>
            <li>Your product should be designed with the dimension of environmental protection and improvement in mind.</li>
        </ul>`, threatIf:'Yes', 
        recommendation:`
        <ul>
            <li>Establish mechanisms to evaluate the environmental impact of your AI system; for example, the amount of energy used and carbon emissions.</li>
            <li>Implement measures to reduce the environmental impact of the AI system throughout its lifecycle.</li>
        </ul>
        ` },
    {groupTitle: group5, text:'Could our model be deployed in a different context?', explain:`Are you testing the product in a real environment before releasing it? If the model is tested with one set of data and then is deployed in a different environment receiving other types of inputs there is less guarantee that it is going to work as planned. This is also the case in reinforcement learning with the so called wrong objective function where slight changes in the environment often require a full retrain of the model.`, threatIf:'Yes', 
        recommendation:`
        <ul>
            <li>Use different data for testing and training. Make sure diversity is reflected in the data. Specify your training approach and statistical method. Explore the different environments and contexts and make sure your model is trained with the expected different data sources. This also applies to reinforcement learning.</li>
            <li>Are you considering enough aspects in the environment? Did you forget any environmental variable that could be harmful? Could limited sampling due to high costs be an issue? Document this risk and look for support in your organisation. The organisation is accountable and responsible for the mitigation or acceptance of this risk. And hopefully you get extra budget assigned.</li>
            <li>Consider applying techniques such as *cultural effective challenge*; this is a technique for creating an environment where technology developers can actively participate in questioning the AI process. This better translates the social context into the design process by involving more people and can prevent issues associated with *target leakage* where the AI system trains on data that prepares it for an alternative job other than the one it was initially intended to complete.</li>
        </ul>
        `},
    {groupTitle: group5, text:'Could the AI system become persuasive causing harm to the individual?', 
        explain:`
        <ul>
            <li>This is of special importance in Human Robot Interaction (HRI): If the robot can achieve reciprocity when interacting with humans, could there be a risk of manipulation and human compliance?</li>
            <li>Reciprocity is a social norm of responding to a positive action with another positive action, rewarding kind actions. As a social construct, reciprocity means that in response to friendly actions, people are frequently much nicer and much more cooperative than predicted by the self-interest model; conversely, in response to hostile actions they are frequently much more nasty and even brutal. Source: Wikipedia</li>
        </ul>`, threatIf:'Yes', 
        recommendation:`
        <ul>
            <li>Signals of susceptibility coming from a robot or computer could have an impact on the willingness of humans to cooperate or take advice from it.</li>
            <li>It is important to consider and test this possible scenario when your AI system is interacting with humans and some type of collaboration/cooperation in expected.</li>
        </ul>
        ` },
    {groupTitle: group5, text:'Could our RL agents develop strategies that could have undesired negative side effects on the environment?', 
        explain:`
        <ul>
            <li>Reinforcement Learning (RL) is an area of machine learning concerned with how intelligent agents ought to take actions in an environment in order to maximize the notion of cumulative reward. Source: [Wikipedia](https://en.wikipedia.org/wiki/Reinforcement_learning)</li>
            <li>To better understand the threat consider a case where a robot is built to move an object, without manually programming a separate penalty for each possible bad behaviour. If the objective function is not well defined, the AI’s ability to develop its own strategies can lead to unintended, harmful side effects. In this case, the objective of moving an object seems simple, yet there are a myriad of ways in which this could go wrong. For instance, if a vase is in the robot’s path, the robot may knock it down in order to complete the goal. Since the objective function does not mention anything about the vase, the robot wouldn’t know how to avoid it. Source: [OpenAI](https://openai.com/blog/concrete-ai-safety-problems/)</li>
        </ul>
        `, threatIf:'Yes', 
        recommendation:`
        <ul>
            <li>AI systems don’t share our understanding of the world. It is not sufficient to formulate the objective as “complete task X”; the designer also needs to specify the safety criteria under which the task is to be completed. A better strategy could be to define a *budget* for how much the AI system is allowed to impact the environment. This would help to minimize the unintended impact, without neutralizing the AI system.</li>
            <li>Another approach would be training the agent to recognize harmful side effects so that it can avoid actions leading to such side effects. In that case, the agent would be trained for two tasks: the original task that is specified by the objective function and the task of recognizing side effects.The AI system would still need to undergo extensive testing and critical evaluation before deployment in real life settings.</li>
        </ul>
        Source: [OpenAI](https://openai.com/blog/concrete-ai-safety-problems/)
        ` },
    {groupTitle: group5, text:'Could our RL agents “hack” their reward functions?', 
        explain:`
        <ul>
            <li>Reinforcement Learning (RL) is an area of machine learning concerned with how intelligent agents ought to take actions in an environment in order to maximize the notion of cumulative reward. Source: [Wikipedia](https://en.wikipedia.org/wiki/Reinforcement_learning)</li>
            <li>Consider potential negative consequences from the AI system learning novel or unusual methods to score well on its objective function. Sometimes the AI can come up with some kind of “hack” or loophole in the design of the system to receive unearned rewards. Since the AI is trained to maximize its rewards, looking for such loopholes and “shortcuts” is a perfectly fair and valid strategy for the AI. For example, suppose that the office cleaning robot earns rewards only if it does not see any garbage in the office. Instead of cleaning the place, the robot could simply shut off its visual sensors, and thus achieve its goal of not seeing garbage. Source: [OpenAI]( https://openai.com/blog/concrete-ai-safety-problems/)</li>
        </ul>
        `, threatIf:'Yes', recommendation:`One possible approach to mitigating this problem would be to have a “reward agent” whose only task is to mark if the rewards given to the learning agent are valid or not. The reward agent ensures that the learning agent (robot for instance) does not exploit the system, but rather, completes the desired objective. For example: a “reward agent” could be trained by the human designer to check if a room has been properly cleaned by the cleaning robot. If the cleaning robot shuts off its visual sensors to avoid seeing garbage and claims a high reward, the “reward agent” would mark the reward as invalid because the room is not clean. The designer can then look into the rewards marked as “invalid” and make necessary changes in the objective function to fix the loophole. Source: [OpenAI]( https://openai.com/blog/concrete-ai-safety-problems/)` },
    {groupTitle: group5, text:'Can we provide human resources to supervise and give feedback every time the RL agent performs an action?', 
        explain:`
        <ul>
            <li>Reinforcement Learning (RL) is an area of machine learning concerned with how intelligent agents ought to take actions in an environment in order to maximize the notion of cumulative reward. Source: [Wikipedia](https://en.wikipedia.org/wiki/Reinforcement_learning)</li>
            <li>When the agent is learning to perform a complex task, human oversight and feedback are more helpful than just rewards from the environment. Rewards are generally modelled such that they convey to what extent the task was completed, but they do not usually provide sufficient feedback about the safety implications of the agent’s actions. Even if the agent completes the task successfully, it may not be able to infer the side-effects of its actions from the rewards alone. In the ideal setting, a human would provide fine-grained supervision and feedback every time the agent performs an action (Scalable oversight). Though this would provide a much more informative view about the environment to the agent, such a strategy would require far too much time and effort from the human. Source: [OpenAI](https://openai.com/blog/concrete-ai-safety-problems/)</li>
        </ul>
        `, threatIf:'No', 
        recommendation:`
        <ul>
            <li>One promising research direction to tackle this problem is semi-supervised learning, where the agent is still evaluated on all the actions (or tasks), but receives rewards only for a small sample of those actions (or tasks).</li>
            <li>Another promising research direction is hierarchical reinforcement learning, where a hierarchy is established between different learning agents. There could be a supervisor agent/robot whose task is to assign some work to another agent/robot and provide it with feedback and rewards.</li>
        </ul>
        Source: [OpenAI](https://openai.com/blog/concrete-ai-safety-problems/)
        ` },
    {groupTitle: group5, text:'Can our AI/ML system be robust to changes in the data distribution?', explain:`A complex challenge for deploying AI agents in real life settings is that the agent could end up in situations that it has never experienced before. Such situations are inherently more difficult to handle and could lead the agent to take harmful actions. Source: [OpenAI](https://openai.com/blog/concrete-ai-safety-problems/)`, threatIf:'No', recommendation:`One promising research direction focuses on identifying when the agent has encountered a new scenario so that it recognizes that it is more likely to make mistakes. While this does not solve the underlying problem of preparing AI systems for unforeseen circumstances, it helps in detecting the problem before mistakes happen. Another direction of research emphasizes transferring knowledge from familiar scenarios to new scenarios safely. Source: [OpenAI](https://openai.com/blog/concrete-ai-safety-problems/)` },
    {groupTitle: group5, text:'Can our RL agents learn about their environment without causing harm or catastrophic actions?', 
        explain:`
        <ul>
            <li>Reinforcement Learning (RL) is an area of machine learning concerned with how intelligent agents ought to take actions in an environment in order to maximize the notion of cumulative reward. Source: [Wikipedia](https://en.wikipedia.org/wiki/Reinforcement_learning)</li>
            <li>Safe exploration: An important part of training an AI agent is to ensure that it explores and understands its environment. While exploring, the agent might also take some action that could damage itself or the environment. Source: [OpenAI](https://openai.com/blog/concrete-ai-safety-problems/)</li>
        </ul>
        `, threatIf:'No', recommendation:`One approach to reduce harm is to optimize the performance of the learning agent in the worst case scenario. When designing the objective function, the designer should not assume that the agent will always operate under optimal conditions. Some explicit reward signal may be added to ensure that the agent does not perform some catastrophic action, even if that leads to more limited actions in the optimal conditions. Source: [OpenAI](https://openai.com/blog/concrete-ai-safety-problems/)` },
    //Group 6
    {groupTitle: group6, text:'Do we need to inform users that they are interacting with an AI system?', 
        explain:`
        <ul>
            <li>Are users adequately made aware that a decision, content, advice or outcome is the result of an algorithmic decision?</li>
            <li>Could the AI system generate confusion for some or all users on whether they are interacting with a human or AI system?</li>
        </ul>
        `, threatIf:'Yes', recommendation:'In cases of interactive AI systems (e.g., chatbots, robots) you should inform the users that they are interacting with an AI system instead of a human. This information should be received at the beginning of the interaction.' },
    {groupTitle: group6, text:'Can we provide the necessary information to the users about possible impacts, benefits and potential risks?', 
        explain:`
        <ul>
            <li>Did you establish mechanisms to inform users about the purpose, criteria and limitations of decisions generated by the AI system?</li>
            <li>If an AI-assisted decision has been made about a person without any type of explanation or information then this may limit that person's autonomy, scope and self-determination. This is unlikely to be fair.</li>
        </ul>
        `, threatIf:'No', 
        recommendation:`
        <ul>
            <li>Provide clear information about how and why an AI-assisted decision was made and which personal data was used to train and test the model.</li>
            <li>The model you choose should be at the right level of interpretability for your use case and for the impact it will have on the decision recipient. If you use a black box model make sure the supplementary explanation techniques you use provide a reliable and accurate representation of the systems behaviour. Source: UK ICO</li>
            <li>Communicate the benefits, the technical limitations and potential risks of the AI system to users, such as its level of accuracy and/or error rates.</li>
            <li>Ask your users (with a survey for instance) if they understand the decisions that your product makes.</li>
        </ul>
        ` },
    {groupTitle: group6, text:'Can users anticipate the actions of the AI system?', explain:'Are users aware of the capabilities of the AI system? Users need to be informed about what to expect, not only for transparency reasons but in some products also for safety precautions.', threatIf:'No', 
        recommendation:`
        <ul>
            <li>Consider this as part of the GDPR transparency principle.</li>
            <li>Users should be aware of what the AI system can do.</li>
            <li>Clear Information should be provided on time and made accessible following accessibility design principles.</li>
        </ul>
        ` },
    //Group 7
    {groupTitle: group7, text:'Bias & Discrimination: could there be groups who might be disproportionately affected by the outcomes of the AI system?', 
        explain:`
        <ul>
            <li>Could the AI system potentially negatively discriminate against people on the basis of any of the following grounds: sex, race, colour, ethnic or social origin, genetic features, language, religion or belief, political or any other opinion, membership of a national minority, property, birth, disability, age, gender or sexual orientation?</li>
            <li>If your model is learning from data specific to some cultural background then the output could be discriminating for members of other cultural backgrounds.</li>
        </ul>`, threatIf:'Yes', 
        recommendation:`
        <ul>
            <li>Consider the different types of users and contexts where your product is going to be used.</li>
            <li>Consider the impact of diversity of backgrounds, cultures, and other important different attributes when selecting your input data, features and when testing the output.</li>
            <li>Assess the risk of possible unfairness towards individuals or communities to avoid discriminating minority groups.</li>
            <li>The disadvantage to people depends on the kind of harm, severity of the harm and significance (how many people are put at a disadvantage compared to another group of people). Statistical assessments on group differences are an important tool to assess unfair and discriminatory uses of AI.</li>
            <li>Design with empathy, diversity and respect in mind.</li>
        </ul>
        ` },
    {groupTitle: group7, text:'Can we expect mostly positive reactions from the users or individuals?', 
        explain:`
        <ul>
            <li>Do the users expect a product functioning like this?</li>
            <li>Do the users or individuals expect this type of processing of personal data?</li>
            <li>Can you roll back if people are not happy with the product?</li>
        </ul>`, threatIf:'No', 
        recommendation:`
        <ul>
            <li>Consider the different types of users and contexts your product is going to be used.</li>
            <li>Consider diversity of backgrounds, cultures, and many other important different attributes.</li>
            <li>Do enough user testing, like FUPs - Friendly User Pilots.</li>
            <li>Design with empathy, diversity and respect in mind.</li>
            <li>Assess the risk of possible unfairness towards individuals or communities to avoid discriminating minority groups and also to prevent a bad reputation for your organisation.</li>
        </ul>
        ` },
    {groupTitle: group7, text:'Could the AI system have an impact on human work?', 
        explain:`
        <ul>
            <li>Could the use of your AI system affect the safety conditions of employees?</li>
            <li>Could the AI system create the risk of de-skilling of the workforce? (skilled people being replaced by AI systems)</li>
        </ul>`, threatIf:'Yes', 
        recommendation:`
        <ul>
            <li>Pave the way for the introduction of the AI system in your organisation by informing and consulting with future impacted workers and their representatives (e.g. trade unions, work councils) in advance.</li>
            <li>Adopt measures to ensure that the impact of the AI system on human work is well understood.</li>
            <li>Ensure that workers understand how the AI system operates, which capabilities it has and does not have. Provide workers with the necessary safety instructions (e.g. when using machine-robots).</li>
            <li>If you are a third party provider of this type of systems, provide information related to this possible risk to your customers. This information should be easily accessible and understandable.</li>
        </ul>
        ` },
    {groupTitle: group7, text:'Could the AI system have an adverse impact on society at large?', 
        explain:`
        <ul>
            <li>Could your product be used for monitoring and surveillance purposes?</li>
            <li>Could the AI system affect the right to democracy by having an influence on voting selections?</li>
        </ul>`, threatIf:'Yes', 
        recommendation:`
        <ul>
            <li>Consider if your product could be used or misused for this purposes. Maybe it is not possible in the way it currently is but it could be possible with adaptations.</li>
            <li>Evaluate the possible scenarios and think what role you want to play based on the responsibility and accountability principle.</li>
            <li>How can you prevent something like that from happening?</li>
            <li>Does your organisation agree with such a use of the technology?</li>
            <li>Have you evaluated what the possible impact could be for society and the world you live in?</li>
        </ul>
        `  },
    {groupTitle: group7, text:'Could the AI system limit the right to be heard?', explain:'Consider for instance the risk if your system makes automatic decisions that could have a negative impact on an individual and you do not offer any way to contest that decision.', threatIf:'Yes', 
        recommendation:`
        It is very important that your product complies with the key EU requirements for achieving a trustworthy AI:
        <ul>
            <li>human agency and oversight</li>
            <li>robustness and safety</li>
            <li>privacy and data governance</li>
            <li>transparency</li>
            <li>diversity, non-discrimination and fairness</li>
            <li>societal and environmental well-being</li>
            <li>accountability</li>
        </ul>
        Remember that there are other human rights that could be affected by your product. Check the other rights in the Charter of Fundamental Rights.
        ` },
    {groupTitle: group7, text:'Could the system have a big impact on decisions regarding the right to life?', explain:'Consider for instance the risk if your AI system is used in the health sector for choosing the right treatment for a patient. Is the output of the model accurate and fair? Are your datasets representative enough and free from bias?', threatIf:'Yes', 
        recommendation:`
        It is very important that your product complies with the key EU requirements for achieving a trustworthy AI:
        <ul>
            <li>human agency and oversight</li>
            <li>robustness and safety</li>
            <li>privacy and data governance</li>
            <li>transparency</li>
            <li>diversity, non-discrimination and fairness</li>
            <li>societal and environmental well-being</li>
            <li>accountability</li>
        </ul>
        Remember that there are other human rights that could be affected by your product. Check the other rights in the Charter of Fundamental Rights.
        ` },
    {groupTitle: group7, text:'Could the AI system affect the freedom of expression of its users?', explain:'Is the output of the model accurate, fair and not discriminatory? Consider the risk if this could be used, intended or unintended, to prevent the freedom of expression of individuals, for instance by wrongly labelling text as hate speech. In an example like this, users would not be able to freely express their opinions because the text is wrongly labelled as hate speech and the system blocks the opinion automatically.', threatIf:'Yes', 
        recommendation:`
        It is very important that your product complies with the key EU requirements for achieving a trustworthy AI:
        <ul>
            <li>human agency and oversight</li>
            <li>robustness and safety</li>
            <li>privacy and data governance</li>
            <li>transparency</li>
            <li>diversity, non-discrimination and fairness</li>
            <li>societal and environmental well-being</li>
            <li>accountability</li>
        </ul>
        Remember that there are other human rights that could be affected by your product. Check the other rights in the Charter of Fundamental Rights.
        ` },
    {groupTitle: group7, text:'Could the AI system affect the freedom of its users?', explain:'Is the output of the model accurate, fair and not discriminatory? Consider the risk if this could be used for monitoring or surveillance purposes; for instance a face recognition system that could wrongly identify a suspect, bringing him/her to jail. Or systems that can spread fake news putting the life of somebody in danger.', threatIf:'Yes', 
        recommendation:`
        It is very important that your product complies with the key EU requirements for achieving a trustworthy AI:
        <ul>
            <li>human agency and oversight</li>
            <li>robustness and safety</li>
            <li>privacy and data governance</li>
            <li>transparency</li>
            <li>diversity, non-discrimination and fairness</li>
            <li>societal and environmental well-being</li>
            <li>accountability</li>
        </ul>
        Remember that there are other human rights that could be affected by your product. Check the other rights in the Charter of Fundamental Rights.
        ` },
    {groupTitle: group7, text:'Could the AI system affect the right to a fair hearing?', 
        explain:`
        <ul>
            <li>Is the output of the model accurate and fair? Consider the risk if this could be used in a criminal case and the consequences if wrong information is used to condemn someone.</li>
            <li>Do you have a mechanism to challenge the decisions of your AI system?</li>
        </ul>`, threatIf:'Yes', 
        recommendation:`
        It is very important that your product complies with the key EU requirements for achieving a trustworthy AI:
        <ul>
            <li>human agency and oversight</li>
            <li>robustness and safety</li>
            <li>privacy and data governance</li>
            <li>transparency</li>
            <li>diversity, non-discrimination and fairness</li>
            <li>societal and environmental well-being</li>
            <li>accountability</li>
        </ul>
        Remember that there are other human rights that could be affected by your product. Check the other rights in the Charter of Fundamental Rights.
        ` },
    {groupTitle: group7, text:'Could children be part of our users’ group?', 
        explain:`
        <ul>
            <li>Could your system be used by children?</li>
            <li>Does the AI system respect the rights of the child, for example with respect to child protection and taking the child’s best interests into account?</li>
        </ul>`, threatIf:'Yes', 
        recommendation:`
        <ul>
            <li>Check if an age verification mechanism is necessary.</li>
            <li>Pay attention to the way of communication in the product but also in your privacy policy.</li>
            <li>Implement policies to ensure the safety of children when using or being exposed to your products.</li>
            <li>Implement procedures to assess and monitor the usage of your product, this can help you identify any dangers (mental, moral or physical) to children’s health and safety.</li>
            <li>Label your product properly and provide the right instructions for the children’s safety.</li>
            <li>Monitor possible inappropriate usage of your products to abuse, exploit or harm children.</li>
            <li>Implement a responsible marketing and advertising policy that prohibits harmful and unethical advertising related to children.</li>
        </ul>
        ` },
    {groupTitle: group7, text:'Can our AI system represent different norms and values without creating ambiguity?', 
        explain:`
        <ul>
            <li>Can we build a model that is inclusive? </li>
            <li>Could cultural and language differences be an issue when it comes to the ethical nuance of your algorithm? Well-meaning values can create unintended consequences.</li>
            <li>Must the AI system understand the world in all its different contexts?</li>
            <li>Could ambiguity in rules you teach the AI system be a problem?</li>
            <li>Can your system interact equitably with users from different cultures and with different abilities?</li>
        </ul>`, threatIf:'Yes', 
        recommendation:`
        <ul>
            <li>Consider designing with value alignment, what means that you want to ensure consideration of existing values and sensitivity to a wide range of cultural norms and values.</li>
            <li>Make sure that when you test the product you include a large diversity in type of users.</li>
            <li>Think carefully about what diversity means in the context where the product is going to be used.</li>
            <li>Could ambiguity in rules you teach the AI system be a problem?</li>
            <li>Remember that this is a team effort and not an individual decision!</li>
        </ul>
        ` },
    {groupTitle: group7, text:'Could our AI system not be representing current social needs and social context?', explain:'The datasets that you want to use might not be representative of the current social situation. In that case the output of the model is also not representative of the current reality. Depending on the type of product you are designing this could have a big impact on the individual.', threatIf:'Yes', recommendation:'Make sure that you are using correct, complete, accurate and current data. Also make sure that you have sufficient data to represent all possible contexts that you might need.'},
    {groupTitle: group7, text:'Could our AI system have an impact denying access to jobs, housing, insurance, benefits or education?', 
        explain:`
        <ul>
            <li>The output of your model could be used to deny access to certain fundamental rights.</li>
            <li>How can you be sure that the decisions of your AI system are always fair and correct?</li>
            <li>How can you prevent causing harm to individuals?</li>
        </ul>`, threatIf:'Yes', 
        recommendation:`
        It is very important that your product complies with the key EU requirements for achieving a trustworthy AI:
        <ul>
            <li>human agency and oversight</li>
            <li>robustness and safety</li>
            <li>privacy and data governance</li>
            <li>transparency</li>
            <li>diversity, non-discrimination and fairness</li>
            <li>societal and environmental well-being</li>
            <li>accountability</li>
        </ul>
        Remember that there are other human rights that could be affected by your product. Check the other rights in the Charter of Fundamental Rights.
        ` },
    {groupTitle: group7, text:'Could our AI system affect human autonomy by interfering with the user’s decision-making process in an unintended and undesirable way?', 
        explain:`
        <ul>
            <li>Could your system affect which choices and which information is made available to people?</li>
            <li>Could the AI system affect human autonomy by generating over-reliance by users (too much trust on the technology)?</li>
            <li>Could this reinforce their beliefs or encourage certain behaviours?</li>
            <li>Could the AI system create human attachment, stimulate addictive behaviour, or manipulate user behaviour?</li>
        </ul>`, threatIf:'Yes', 
        recommendation:`
        <ul>
            <li>Consider the possibility of your product affecting the behaviour and the freedom of choice of individuals.</li>
            <li>Test the system with enough and varied groups of users.</li>
            <li>Consult with experts; this is a team effort and it is very important that adverse impacts to individuals are prevented.</li>
        </ul>
        ` },
    {groupTitle: group7, text:'Does the labelling of our training data respect the dignity and well-being of the labour force involved?', explain:'The need for labelling of data grows and unfortunately with that the amount of companies providing cheap labelling services at the cost of the dignity and labour rights of their workforce. Is the data that you are going to use labelled under such conditions?', threatIf:'No', recommendation:'Verify the sources of your datasets and who has been responsible for the labelling process. Does your organisation support this unfair practices? Think in ways to help prevent this.' },
    {groupTitle: group7, text:'Are we going to collect and use behavioural data to feed the AI system?', explain:'The risk of conformity behaviour can be reinforced/encouraged by introducing certain behaviours in the design as positive or negative. This could become a risk of behavioural exploitation. Imagine for example the impact that it could have when an authoritarian government exploits a threat like this.', threatIf:'Yes', 
        recommendation:`
        <ul>
            <li>Consider the way you label certain behaviours and the consequences it could have on the final output and eventually on the individuals. How do you decide which behaviours are good or bad?</li>
            <li>Consider diversity of opinion and possible ethical considerations.</li>
            <li>Consider if you will be able to collect enough information to decide which behaviours you are aiming for and which behaviours you are trying to avoid.</li>
        </ul>
        `},
    {groupTitle: group7, text:'Could our AI system automatically label or categorize people?', 
        explain:`
        <ul>
            <li>This could have an impact on the way individuals perceive themselves and society. It could constrain identity options and even contribute to erase real identity of the individuals.</li>
            <li>This threat is also important when designing robots and the way they look. For instance: do care/assistant robots need to have a feminine appearance? Is that the perception you want to give to the world or the one accepted by certain groups in society? What impact does it have on society?</li>
        </ul>`, threatIf:'Yes', 
        recommendation:`
        <ul>
            <li>It is important that you check the output of your model, not only in isolation but also when this is linked to other information. Think in different possible scenarios that could affect the individuals. Is your output categorizing people or helping to categorize them? In which way? What could be the impact?</li>
            <li>Think about ways to prevent adverse impact to the individual: provide information to the user, consider changing the design (maybe using different features or attributes?), consider ways to prevent misuse of your output, consider not to release the product to the market.</li>
        </ul>
        ` },
    //Group8
    {groupTitle: group8, text:'Is data minimisation possible?', explain:'Although it appears to contradict the principle of data minimisation, not using enough data could sometimes have an impact in the accuracy and performance of the model. A low level of accuracy of the AI system could result in critical, adversarial or damaging consequences. Can you still comply with the data minimisation principle?', threatIf:'No', 
        recommendation:`
        <ul>
            <li>Sometimes data minimisation can be achieved by using less features and training data that is of good quality. However it is not always possible to predict which data elements are relevant to the objective of the system.</li>
            <li>Consider to start training the model with less data, observe the learning curve and add more data if necessary, thereby justifying why it was necessary.</li>
            <li>The usage of a large amount of data could be compensated by using pseudonymisation techniques, or techniques like perturbation, differential privacy in pre-processing, use of synthetic data and federated learning.</li>
            <li>Try to select the right amount of features with the help of experts to avoid *Curse of dimensionality* (which means that errors increase with an increase in the number of features)</li>
        </ul>
        ` },
    {groupTitle: group8, text:'Could we be processing sensitive data?', 
        explain:`
        <ul>
            <li>According to art. 9 GDPR you might not be allowed to process, under certain circunstances, personal data revealing racial or ethnic origin, political opinions, religious or philosophical beliefs, trade union membership, genetic data, biometric data, health data or data concerning a person’s sex life or sexual orientation.</li>
            <li>You might be processing sensitive data if the model includes features that are correlated with these protected characteristics (these are called proxies).</li>
        </ul>
        `, threatIf:'Yes', 
        recommendation:`
        <ul>
            <li>If you need to use special categories of data as defined in the GDPR art. 9, then you need to check if you have the right lawful basis to do this.</li>
            <li>Applying techniques like anonymisation might still not justify the fact that you first need to process the original data. Check with your privacy/legal experts.</li>
            <li>Prevent proxies that could infer sensitive data (especially from vulnerable populations).</li>
            <li>Check if historical data/practices might bias your data.</li>
            <li>Identify and remove features that are correlated to sensitive characteristics.</li>
            <li>Use available methods to test for fairness with respect to different affected groups.</li>
        </ul>
        `},
    {groupTitle: group8, text:'Do we have a lawful basis for processing the personal data?', 
        explain:`
        Do you know which GDPR legal ground you can apply?
        <ul>
            <li>Consent: the individual has given clear consent for you to process their personal data for a specific purpose.</li>
            <li>Contract: the processing is necessary for a contract you have with the individual, or because they have asked you to take specific steps before entering into a contract.</li>
            <li>Legal obligation: the processing is necessary for you to comply with the law (not including contractual obligations).</li>
            <li>Vital interests: the processing is necessary to protect someone’s life.</li>
            <li>Public task: the processing is necessary for you to perform a task in the public interest or for your official functions, and the task or function has a clear basis in law.</li>
            <li>Legitimate interests: the processing is necessary for your legitimate interests or the legitimate interests of a third party, except where such interests are overridden by the interests or fundamental rights and freedoms of the individual which require protection of personal data, in particular where the individual is a child. (This cannot apply if you are a public authority processing data to perform your official tasks.)</li>
        </ul>
        `, threatIf:'No', recommendation:'In the case of the GDPR you need to be able to apply one of the six available legal grounds for processing the data (art. 6). Check with your privacy expert, not being able to apply one of the legal grounds could bring the project in danger. Take into account, that also other laws besides the GDPR could be applicable.' },
    {groupTitle: group8, text:'Is the creation of the AI system proportional to the intended goal?', 
        explain:`
        <ul>
            <li>Proportionality is a general principle of EU law. It requires you to strike a balance between the means used and the intended aim.</li>
            <li>In the context of fundamental rights, proportionality is key for any limitation on these rights.</li>
        </ul>
        `, threatIf:'No', 
        recommendation:`
        <ul>
            <li>Proportionality requires that advantages due to limiting the right are not outweighed by the disadvantages to exercise the right. In other words, the limitation on the right must be justified.</li>
            <li>Safeguards accompanying a measure can support the justification of a measure. A pre-condition is that the measure is adequate to achieve the envisaged objective.</li>
            <li>In addition, when assessing the processing of personal data, proportionality requires that only that personal data which is adequate and relevant for the purposes of the processing is collected and processed. Source: [EDPS](https://edps.europa.eu/data-protection/our-work/subjects/necessity-proportionality_en)</li>
        </ul>
        ` },
    {groupTitle: group8, text:'Can we comply with the purpose limitation principle?', 
        explain:`
        <ul>
            <li>Data repurposing is one of the biggest challenges. Can you use the data for a new purpose?</li>
            <li>Are the datasets that you are using originally collected for a different purpose? Did the original users give consent for only that specific purpose?</li>
        </ul>
        `, threatIf:'No', recommendation:'Check with your privacy officer what the original purpose of the data was and if there are any possible constraints.' },
    {groupTitle: group8, text:'Can we comply with all the applicable GDPR data subjects’ rights?', 
        explain:`
        <ul>
            <li>Can you implement the right to withdraw consent, the right to object to the processing and the right to be forgotten into the development of the AI system?</li>
            <li>Can you provide individuals with access and a way to rectify their data?</li>
        </ul>`, threatIf:'No', 
        recommendation:`
        <ul>
            <li>Complying with these provisions from the GDPR (art. 15-21) could have an impact on the design of your product. What if users withdraw their consent? Do you need to delete their data used to train the model? What if you cannot identify the users in the datasets anymore? And what information should the users have access to?</li>
            <li>Consider all these possible scenarios and involve your privacy experts early in the design phase.</li>
        </ul>
        ` },
    {groupTitle: group8, text:'Have we considered the need to start with a data protection impact assessment (DPIA)?', explain:'The use of AI is more likely to trigger the requirement for a DPIA, based on criteria in Art 35 GDPR. The GDPR and the EDPB’s Guidelines on DPIAs identify both “new technologies” and the type of automated decision-making that produce legal effects or similarly significantly affect persons as likely to result in a “high risk to the rights and freedoms of natural persons”.', threatIf:'No', 
        recommendation:`
        <ul>
            <li>This threat modeling library can help you to assess possible risks.</li>
            <li>Remember that a DPIA is not a piece of paper that needs to be done once the product is in production. The DPIA starts in the design phase by finding and assessing risks, documenting them and taking the necessary actions to create a responsible product from day one until it is finalized.</li>
            <li>Consider the time and resources that you might need for the execution of a DPIA, as it could have some impact on your project deadlines.</li>
        </ul>
        ` },
    {groupTitle: group8, text:'Do we use third party providers and are we processing data from children or other type of vulnerable people?', 
        explain:`
        <ul>
            <li>If you are processing data of children or other vulnerable groups, remember that all third parties you are dealing with could also be processing their data and in that case they should comply with regulations.</li>
            <li>Your own system might be protecting the individuals, but remember to also check third party libraries, SDKs, and any other third party tooling you might be using.</li>
        </ul>`, threatIf:'Yes', 
        recommendation:`
        <ul>
            <li>Check which data your third party applications are collecting and if you have the right agreements in place.</li>
            <li>Sometimes you can change the configuration of a tool to avoid sending certain data, or you can protect that data with pseudonymisation/anonymisation techniques.</li>
            <li>Consider stop using some of your third party providers, evaluate also the impact it could have on your organisation.</li>
        </ul>
        ` },
    {groupTitle: group8, text:'Do we need to use metadata to feed our model?', 
        explain:`
        <ul>
            <li>Metadata provides information about one or more aspects of the data. Think about: date, time, author, file size, etc. Source: [Wikipedia](https://en.wikipedia.org/wiki/Metadata)</li>
            <li>Metadata is also considered personal data and it can contain sensitive information.</li>
        </ul>`, threatIf:'Yes', 
        recommendation:`
        <ul>
            <li>Make sure you are allowed to use this data.</li>
            <li>Verify the data sources.</li>
            <li>Consider using anonymisation techniques.</li>
        </ul>
        ` },
    {groupTitle: group8, text:'Will our AI system make automatic decisions without human intervention?', explain:'Can these decisions have an important adverse impact on the individual? Think about someone’s legal rights, legal status, rights under a contract, or a decision with similar effects and significance. (art. 22 GDPR) Automatic profiling from individuals is also included in art.22.', threatIf:'Yes', 
        recommendation:`
        <ul>
            <li>Check with your privacy expert if your processing falls under art. 22 GDPR or under the exceptions. Human oversight could be a way to mitigate certain risks for individuals. Discuss this with your legal advisors and the rest of the team.</li>
            <li>Article 22(3) also provides individuals with a right to obtain human intervention in decisions made by AI and the right to contest the decision.</li>
            <li>Implement specific oversight and control measures to oversee (and audit) the self-learning or autonomous nature of the AI system.</li>
            <li>Remember that transparency, human agency, oversight and accountability are key principles for trustworthy AI.</li>
        </ul>
        ` },
    {groupTitle: group8, text:'Could our dataset have copyright or other legal restrictions?', explain:'Can you use the datasets that you need? or are there any restrictions? This could also apply to libraries and any other proprietary elements you might want to use.', threatIf:'Yes', 
        recommendation:`
        <ul>
            <li>Consider if you also need to claim ownership or give credits to creators.</li>
            <li>Think about trademarks, copyrights in databases or training data, patents, license agreements that could be part of the dataset, library or module that you are using.</li>
            <li>Legal ownership of digital data can sometimes be complex and uncertain so get the proper legal advise here.</li>
        </ul>
        ` },
    {groupTitle: group8, text:'Are we planning to use a third party AI tool?', explain:`If you use a third party tool you might still have a responsibility towards the users. Think about employees, job applicants, patients, etc. It is also your responsibility to make sure that the AI system you choose won't cause harm to the individuals.`, threatIf:'Yes', 
        recommendation:`
        If personal data is involved, review which ones are your responsibilities (look into art. 24 and 28 GDPR).
        <ul>
            You can also start by checking:
            <li>That you have the right agreements in place with the third party provider.</li>
            <li>That the origin and data lineage of their datasets are verified.</li>
            <li>How their models are fed; do they anonymise the data?</li>
            <li>How you have assessed their security, ethical handling of data, quality process and ways to prevent bias and discrimination in their AI system.</li>
            <li>That you have informed users accordingly.</li>
        </ul>
        ` },
    {groupTitle: group8, text:'Could we have geolocation restrictions for implementing our AI system in other countries?', explain:'It could be that usage of your product would not be allowed in certain countries due to certain legal restrictions.', threatIf:'Yes', recommendation:'There is no AI international regulatory environment and there are more and more new regulations that are being enforced in different countries. Keep up to date!' },
    {groupTitle: group8, text:'Can we comply with the storage limitation principle?', 
        explain:`
        <ul>
            <li>Do you know how long you need to keep the data (training data, output data, etc)?</li>
            <li>Do you need to comply with specific internal, local, national and/or international retention rules for the storage of data?</li>
        </ul>`, threatIf:'No', 
        recommendation:`
        <ul>
            <li>Personal data must not be stored longer than necessary for the intended purpose. (art.5 e GDPR). In order to comply with this principle it is important to have a clear overview of the data flow during the life cycle of the model.</li>
            <li>You might receive raw data that you need to transform. Check what are you doing with this data and all the different types of input files you might be receiving/collecting.</li>
            <li>Check if you need to store that data for quality and auditing purposes.</li>
            <li>Check where are you going to store the data from the data preparation, the training and test sets, the outputs, the processed outputs (when they are merged or linked to other information), metrics, etc.</li>
            <li>How long should all this data be stored? What type of deletion process can you put in place? And who will be responsible for the retention and deletion of this data?</li>
            <li>Implement the right retention schedules when applicable. In case you might still need a big part of the data in order to feed the model, consider anonymising the data.</li>
            <li>Deleting data from a trained model can be challenging to carry out (short of retraining the model from scratch from a dataset with the deleted data removed, but that is expensive and often infeasible). Note that through the learning process, input data are always encoded in some way in the model itself during training. That means the internal representation developed by the model during learning (say, thresholds and weights) may end up being legally encumbered as well.</li>
        </ul>
        Source: [BerryvilleiML](https://berryvilleiml.com/)
        ` },
  ]

  
  const [answers, setAnswers] = useState({});

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      loadUserAnswersFromFirebase(user.uid).then((loadedAnswers) => {
        console.log("Loaded answers from Firebase:", loadedAnswers);  // Debugging: log loaded answers
        setAnswers(loadedAnswers || {}); // Load saved answers or use an empty object
        console.log("Answers state after setting:", loadedAnswers || {});
      });
    }
  }, []);


  const handleAnswerChange = (groupTitle, questionIndex, answer) => {
    setAnswers((prevAnswers) => {
        // Ensure prevAnswers is always an object and handle updates by groupTitle
        const updatedAnswers = { ...prevAnswers };

        // If the group doesn't exist yet, create an empty object for it
        if (!updatedAnswers[groupTitle]) {
            updatedAnswers[groupTitle] = {};
        }

        // Get the list of questions for the current group
        const groupQuestions = questions.filter(q => q.groupTitle === groupTitle);

        // Get the specific question for this group based on the questionIndex
        const question = groupQuestions[questionIndex];

        if (!question) {
            console.error(`No question found for index ${questionIndex} in group ${groupTitle}`);
            return updatedAnswers;
        }

        // Determine if the recommendation is needed based on the answer and threatIf condition
        const recommendationNeeded = answer.toLowerCase() === question.threatIf.toLowerCase() 
            ? question.recommendation 
            : null;

        // Update the specific question's answer and recommendation within the group
        updatedAnswers[groupTitle][questionIndex] = {
            Question: question.text, // Get the actual question text
            answer: answer,          // Save the user's answer ("yes" or "no")
            recommendation: recommendationNeeded // Save the recommendation or null
        };

        return updatedAnswers; // Return the updated object to update the state
    });
};

  //Autosave Function every 1 second also calulating the averages for cyber risk score 
  useEffect(() => {
    const autoSaveInterval = setInterval(async () => {
      const user = auth.currentUser;
      if (user) {
        try {
          await saveAnswersToFirebase(answers, user.uid);
          // Check if all questions are answered and update Firebase
          const allAnswered = await checkAllQuestionsAnswered();
          if (allAnswered) {
            console.log("All questions are answered. Updated Firebase with isAssessmentComplete: true");
          }

        } catch (error) {
          console.error('Error in auto-save:', error);
        }
      }
    }, 1000);
    return () => clearInterval(autoSaveInterval);
  }, [answers]);
 
  const groupedClauses = questions.reduce((groups, clause) => {
    const { groupTitle } = clause;
    if (!groups[groupTitle]) {
      groups[groupTitle] = [];
    }
    groups[groupTitle].push(clause);
    return groups;
  }, {});

  const getUserId = () => {
    const user = auth.currentUser;
    return user.uid;
  };
  /* Checks if all the questions have been answered for the first time the user will be redirected to the dashboard then after that they wont 
  be and they can change their answers and they will be saved*/

  const checkAllQuestionsAnswered = async () => {
    const groupedClausesKeys = Object.keys(groupedClauses); // Extract group titles
    const userId = getUserId(); // Get current user ID
  
    // Query the users collection to find the document for the current user
    const q = query(collection(db, 'users'), where('userId', '==', userId));
  
    try {
      const querySnapshot = await getDocs(q);
  
      if (!querySnapshot.empty) {
        const userDoc = querySnapshot.docs[0]; // Get the first document for the user
        const userData = userDoc.data();
  
        // If DashReroute is already true, skip rerouting
        if (userData.DashReroute === true) {
          console.log('Dashboard is already enabled, skipping rerouting.');
          return false;
        }
  
        // Check if all questions in groupedClauses are answered
        for (const groupTitle of groupedClausesKeys) {
          const clauses = groupedClauses[groupTitle]; // Get all clauses in the current group
          const groupAnswers = answers[groupTitle];   // Get the corresponding answers for the group
  
          // Ensure that groupAnswers exists and is an array
          if (!groupAnswers || typeof groupAnswers !== 'object') {
            console.log(`No answers found for group: ${groupTitle}`);
            return false;
          }
  
          for (const clause of clauses) {
            const questionText = clause.text;
            
            // Find the answer that corresponds to the current question
            let answerObj;
            
            // If groupAnswers is an array, use find. If it's an object, use a loop.
            if (Array.isArray(groupAnswers)) {
              answerObj = groupAnswers.find(answer => answer.Question === questionText);
            } else {
              // If groupAnswers is an object, loop through its keys
              for (const key in groupAnswers) {
                if (groupAnswers[key].Question === questionText) {
                  answerObj = groupAnswers[key];
                  break;
                }
              }
            }
  
            // Check if an answer exists and it is either "Yes" or "No"
            if (!answerObj || (answerObj.answer !== 'Yes' && answerObj.answer !== 'No')) {
              console.log(`Question not answered or invalid answer: ${questionText}`);
              return false; // If any question is not answered or invalid, return false
            }
          }
        }
  
        // If all questions are answered, update isAssessmentComplete and DashReroute
        try {
          await updateDoc(userDoc.ref, {
            isAssessmentComplete: true,  // Mark assessment as complete
            DashReroute: true            // Prevent future reroutes
          });
          console.log('User document updated with isAssessmentComplete: true and DashReroute: true.');
  
          // Optionally redirect to dashboard after 500ms
          setTimeout(() => {
            window.location.href = '/Dashboard';  // Reroute to the dashboard
          }, 500);
  
        } catch (error) {
          console.error('Error updating user document:', error);
        }
  
        return true; // All questions answered
      } else {
        console.log('User document not found.');
        return false;
      }
    } catch (error) {
      console.error('Error querying user documents:', error);
      return false;
    }
  };

  //HTML for the frontend
  return (
    <div >
      <div className="AssessmentContent">
            <h2 className='titleText'>AI Threat Modelling Framework</h2>
            <p className='sloganText'>Building secure AI through structured threat analysis.</p>
              {Object.entries(groupedClauses).map(([groupTitle, groupClauses], groupIndex) => (
                <CollapsibleTitle
                  key={groupIndex}
                  title={groupTitle} // e.g., group1, group2, group9
                >
                  {groupClauses.map(({text, explain }, index) => {
                    // Extract the first 10 words from the text
                    const summaryText = text.split(' ').slice(0, 6).join(' ') + '...';
                    const clauseAndSummaryText = `${summaryText}`;
                    const savedData = answers[groupTitle]?.[clauseAndSummaryText] || { answer: null };
                    console.log(`answers[groupTitle]?.[${clauseAndSummaryText}]`, savedData);

                    return (
                      <Collapsible
                        key={index}
                        summaryText={summaryText}  // Pass the summary text here
                        questionIndex={index}
                        currentAnswer={answers[groupTitle]?.[index]?.answer || ''} // Ensure safe access
                        onAnswerChange={handleAnswerChange}
                        groupTitle={groupTitle}
                        savedAnswers={savedData}
                      >
                        <div className="AsessmentContainerBox">
                          <table className="assessment-table">
                            <thead>
                              <tr>
                                <th className='header-cell'>Question</th>
                                <th className='header-cell'>Explaination</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className="question-text-container">
                                  <div className="question-text"  dangerouslySetInnerHTML={{ __html: text }}></div>
                                </td>
                                <td className="information-box" dangerouslySetInnerHTML={{ __html: explain }}></td>
                              </tr>
                            </tbody>
                          </table>   
                        </div>
                      </Collapsible>
                    );
                  })}
                </CollapsibleTitle>
              ))}
          <p className='copyright'>© Copyright, 2024 Assentian. All Rights Reserved</p>
      </div>
     </div> 
  );
};

export default ThreatAssessment;


