// src/components/Copyright.js
import React from 'react';
import './Copyright.css'; 

const Copyright = () => {
  const currentYear = new Date().getFullYear();  // Get the current year dynamically

  return (
    <footer className='footer' >
      <p >
        &copy; Copyright, {currentYear} Assentian. All rights reserved.
      </p>
    </footer>
  );
};

// Simple inline styles for the footer


export default Copyright;
