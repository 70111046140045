import React, { useState } from "react";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../Firebase_Config/firebase";
import TextField from '@mui/material/TextField';
import './AccountMang.css'

function ForgotPassword() {
    const [email, setEmail] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        sendPasswordResetEmail(auth, email)
            .then(() => {
                alert("Check your email for password reset instructions.");
            })
            .catch((error) => {
                alert(error.message);
            });
    };

    return (
      <div style={{
            backgroundImage: `linear-gradient(195deg, rgba(66, 66, 74, 0.6), rgba(25, 25, 25, 0.6)), url('bg.jpeg')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            height: '100vh'
          }}>
        <div className="auth-container" >
            
          <div className="auth-box">
            <div className="sign-in-header">
              <h2 className="Signh2">Forgot Password</h2>
            </div>
            <div>
              <form>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Enter Email Here...."
                    name="email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <button className='Signbutton' type='button' onClick={handleSubmit}>Reset</button>
              </form>
              <p className="sign-up-text">
                Already have an account? <a href="/SignIn">Sign In</a>
              </p>
            </div>
          </div>
        </div>
        <p className='copyright sign'>© Copyright, 2024 Assentian. All Rights Reserved</p>
      </div>
    );
}

export default ForgotPassword;
