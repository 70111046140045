import React from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useState } from "react";
import { auth } from "../Firebase_Config/firebase";
import { Link } from "react-router-dom";
import { Alert } from '@mui/material';
import "./SignIn.css"; // Import the shared CSS file
import Copyright from "../CopyrightFooter/Copyright";

const SignIn = ({ user }) => {
  const initialFormState = {
    email: '',
    password: '',
  };

  const [formData, setFormData] = useState(initialFormState);
  const [error, setError] = useState(null);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const handleSignIn = () => {
    setError(null); // Clear any previous error messages
    let hasError = false;

    // Validate email and password fields
    if (!formData.email) {
      setEmailError(true);
      hasError = true;
    } else {
      setEmailError(false);
    }

    if (!formData.password) {
      setPasswordError(true);
      hasError = true;
    } else {
      setPasswordError(false);
    }

    if (hasError) return; // Stop execution if any field is empty

    // Sign in with email and password
    signInWithEmailAndPassword(auth, formData.email, formData.password)
      .then((userCredential) => {
        const user = userCredential.user;
        console.log(user);
        window.location.href = '/Assessment'; // Redirect to assessment page
      })
      .catch((error) => {
        const errorMessage = error.message;
        console.log(error.code, errorMessage);
        setError(errorMessage); // Set Firebase sign-in error
      });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setError(null); // Clear general errors when typing
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div style={{
        backgroundImage: `linear-gradient(195deg, rgba(66, 66, 74, 0.6), rgba(25, 25, 25, 0.6)), url('bg.jpeg')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        height: '100vh',}}>
      <div className="auth-container">
        <div className="auth-box">
          <div className="sign-in-header">
            <h2 className="Signh2">Sign In</h2>
          </div>
          <div>
            {error && <Alert variant="filled" severity="error" className="error-message">{error}</Alert>}
            <form>
              <input
                className={`form_input ${emailError ? 'input-error' : ''}`}
                type="text"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="Email"
              />
              {emailError && <Alert severity="warning" className="field-error">Please enter your email</Alert>}

              <input
                className={`form_input ${passwordError ? 'input-error' : ''}`}
                type="password"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                placeholder="Password"
              />
              {passwordError && <Alert severity="warning" className="field-error">Please enter your password</Alert>}

              <button className="Signbutton" type="button" onClick={handleSignIn}>SIGN IN</button>
            </form>
            <p className="sign-up-text">
              Don't have an account? <a href="/SignUp">Sign up</a>
            </p>
            <p className="sign-up-text">Forgot your Password? <a href="/ForgotPassword">Click Here</a> </p>
          </div>
        </div>
      </div>
      <p className='copyright sign'>© Copyright, 2024 Assentian. All Rights Reserved</p>
    </div>

  );
};

export default SignIn;
