import React, { useEffect, useState } from 'react';
import Papa from 'papaparse';
import './AiDatabase.css';

// Path to your uploaded CSV file (ensure it is placed in your public folder for this example)
const csvFilePath = './reports.csv';

function AiDatabase() {
  const [incidents, setIncidents] = useState([]);

  useEffect(() => {
    // Parse the CSV file and log any errors
    Papa.parse(csvFilePath, {
      download: true,
      header: true,
      complete: (result) => {
        console.log('CSV Parse Complete: ', result);
        setIncidents(result.data);
      },
      error: (error) => {
        console.error('Error parsing CSV: ', error);
      },
    });
  }, []);

  return (
    <div className='AiDatabaseContent'>
      <div className="AiDatabase">
        <h1 className='aiDatabaseTitle'>AI Incident Reports</h1>
        <div className="incident-container">
          {incidents.length === 0 ? (
            <p>No incidents found. Check if CSV is loading correctly.</p>
          ) : (
            incidents.map((incident, index) => {
              // Log each incident for debugging purposes
              console.log('Rendering incident: ', incident);
  
              // Check if required fields exist in each incident
              if (!incident.title || !incident.url || !incident.description || !incident.date_published) {
                console.error('Missing data in incident: ', incident);
                return null;  // Skip this item if data is missing
              }
  
              return (
                <div key={index} className="incident">
                  <h2>
                      <a href={incident.url} target="_blank" rel="noopener noreferrer">
                        {incident.title}
                      </a>
                  </h2>
                  <p>{incident.description}</p>
                  <p><strong>Published on:</strong> {new Date(incident.date_published).toDateString()}</p>
                  <p><strong>Source:</strong> {incident.source_domain}</p>
                </div>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
}

export default AiDatabase;
