import React, { useState, useEffect } from 'react';
import './dash.css'
import { MdStore } from "react-icons/md";
import { FaSquare, FaPiggyBank, FaUserClock, FaCoins } from "react-icons/fa";
import {  RiSwordFill  } from "react-icons/ri";
import { IoPersonAdd } from "react-icons/io5";
import { db, auth } from '../Firebase_Config/firebase';
import { collection, query, where, orderBy, limit, getDocs, doc, getDoc } from 'firebase/firestore';
import { Line, Bar } from 'react-chartjs-2';
import 'chart.js/auto'; // This automatically registers all the components from Chart.js
import pattern from 'patternomaly'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import { AiFillSecurityScan, AiFillSafetyCertificate  } from "react-icons/ai";
import { BsShieldFillExclamation } from "react-icons/bs";
import { GiSwordsEmblem, GiPayMoney, GiCash } from "react-icons/gi";
import { SiCyberdefenders } from "react-icons/si";
import { LuCalendarClock } from "react-icons/lu";
import { FaMoneyBillWave } from "react-icons/fa";
import Copyright from '../CopyrightFooter/Copyright';
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { MdOutlineCenterFocusWeak } from "react-icons/md";
import { PiStrategyBold, PiIdentificationCardFill } from "react-icons/pi";
import { FaUniversalAccess } from "react-icons/fa6";
import { ImConfused2 } from "react-icons/im";
import { HiMiniUserGroup } from "react-icons/hi2";
import { GrCompliance } from "react-icons/gr";
import axios from 'axios';





const Collapsible = ({ summaryText, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="collapsible">
      <div className="collapsible-header">
        <div className="arrow" onClick={() => setIsOpen(!isOpen)}>
          <MdOutlineCenterFocusWeak className='AIicon2' />
          {isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
          <span>{summaryText}</span>
        </div>
      </div>
      {isOpen && <div className="collapsible-content">{children}</div>}
    </div>
  );
};

const groups = [
  'Techniques and Processes',
  'Accessibility',
  'Identifiability and Linkability',
  'Security',
  'Safety',
  'Unawareness',
  'Ethics and Human Rights',
  'Non-Compliance',
];

const getUserId = () => {
  const user = auth.currentUser;
  return user ? user.uid : null; // Ensure user is logged in
};

const Dashboard = () => {
  const [answers, setAnswers] = useState({});
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [threatLevel, setThreatLevel] = useState('');
  const [numOfthreats, setNumOfThreats]= useState('');
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [isDashModalOpen, setisDashModalOpen] = useState(false);

  // Fetch answers from Firestore
  useEffect(() => {
    const fetchAnswersForDash = async () => {
      try {
        const userId = getUserId(); // Fetch user ID
        if (!userId) {
          console.log('User not authenticated');
          return;
        }
        const answersDocRef = doc(db, 'Answers', userId);
        const answersSnapshot = await getDoc(answersDocRef);

        if (answersSnapshot.exists()) {
          const data = answersSnapshot.data();
          console.log('Answers Data:', data); // For debugging
          setAnswers(data); // Store all answers
          calculateThreatLevel(data);
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.error('Error fetching document:', error);
      }
    };

    fetchAnswersForDash();
  }, []);
  
  const calculateThreatLevel = (answersData) => {
    let totalThreats = 0;

    // Iterate through each group and count the number of recommendations
    groups.forEach((group) => {
      const groupAnswers = answersData[group] || {};
      const threatCount = Object.values(groupAnswers).filter(item => item.recommendation !== null).length;
      totalThreats += threatCount;
    });

    // Assign threat level based on the total number of threats
    let level = '';
    if (totalThreats < 20) {
      level = 'Low';
    } else if (totalThreats >= 20 && totalThreats <= 60) {
      level = 'Medium';
    } else {
      level = 'High';
    }
    setNumOfThreats(totalThreats)
    setThreatLevel(level); // Update the threat level state
  };

  const getProgressBarWidth = () => {
    const maxThreats = 86; // You can adjust this value
    return `${Math.min((numOfthreats / maxThreats) * 100, 100)}%`; // Max at 100%
  };

  // Determine progress bar color based on threat level
  const getProgressBarClass = () => {
    if (threatLevel === 'Low') return 'low';
    if (threatLevel === 'Medium') return 'medium';
    if (threatLevel === 'High') return 'high';
  };

  // Modal component
  const DashModal = ({ isOpen, onClose }) => {
    if (!isOpen || !selectedGroup) return null; // Return null if modal is closed or no group selected
  
    const groupAnswers = answers[selectedGroup]; // Get the specific group answers
  
    // Ensure groupAnswers is an array. If it's not, convert it.
    const groupData = Array.isArray(groupAnswers) ? groupAnswers : Object.values(groupAnswers || {});
  
    // Filter out the questions that do not have a recommendation
    const filteredData = groupData.filter(item => item.recommendation !== null);
  
    return (
      <div className="dashModal">
        <div className='dashModalContent'>
          <div className="dashModalBox">
            <h2>{selectedGroup}</h2>
            {filteredData.map((item, index) => (
              <Collapsible key={index} summaryText={item.Question}>
                <div className='AsessmentContainerBox'>
                  <table className="assessment-table">
                    <thead>
                      <tr>
                        <th className="header-cell">Question</th>
                        <th className="header-cell">Recommendation</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="question-text-container">
                          <div className="question-text">{item.Question}</div>
                        </td>
                        <td className="information-box">
                          <div className="cohere-description" onClick={() => handleOpenModal(item.recommendation)} dangerouslySetInnerHTML={{ __html: item.recommendation }}></div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Collapsible>
            ))}
             <Modal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                messages={messages}
                isTyping={isTyping}
                input={input}
                handleInputChange={handleInputChange}
                sendMessage={sendMessage}
                />
            <button className='closeButton' onClick={onClose}>Close</button>
          </div>
        </div>
      </div>
    );
  };

  // Handle modal opening
  const openModal = (group) => {
    console.log(`Opening modal for: ${group}`); // Debugging
    setSelectedGroup(group);
    setisDashModalOpen(true);
  };

  const closeModal = () => {
    setisDashModalOpen(false);
    setSelectedGroup(null);
  };

  const Modal = ({ isOpen, onClose, messages, isTyping, input, handleInputChange, sendMessage }) => {
    if (!isOpen) return null;

    return (
    <div className="AicontentPop">
        <div className="AIContainerBoxPop">
        <div className="AiheaderPop">
            <h1 className="AIh1">Threat Modelling Co-Pilot</h1>
            </div>
        <div className="chatbot-containerPop">
            <div className="chat-messages">
            {messages.map((msg, idx) => (
                <div key={idx} className={`message ${msg.sender}`}>
                {msg.text}
                </div>
            ))}
            {isTyping && <div className="typing-indicator">Typing...</div>}
            </div>
            <div className="inputBox">
            <form onSubmit={sendMessage}>
                <input
                className="promptInput"
                type="text"
                value={input}
                onChange={handleInputChange}
                placeholder="Ask a question..."
                />
                <button className="aibutton" type="submit">
                <svg height="24" width="24" fill="#FFFFFF" viewBox="0 0 24 24" data-name="Layer 1" id="Layer_1" class="sparkle">
                    <path d="M10,21.236,6.755,14.745.264,11.5,6.755,8.255,10,1.764l3.245,6.491L19.736,11.5l-6.491,3.245ZM18,21l1.5,3L21,21l3-1.5L21,18l-1.5-3L18,18l-3,1.5ZM19.333,4.667,20.5,7l1.167-2.333L24,3.5,21.667,2.333,20.5,0,19.333,2.333,17,3.5Z"></path>
                </svg>
                <span className="aitext">Answer</span>
                </button>
            </form>
            <button className='closeButton' onClick={onClose}>Close</button>
            </div>
        </div>
        
        </div>
    </div>
    );
  };

  const handleOpenModal = async (content) => {
    try {
      setModalOpen(true);
      setIsTyping(true);
  
      // Call Cohere chat API instead of generate API
      const response = await axios.post(
        'https://api.cohere.ai/v1/chat',
        {
          query: `Explain this in non-technical terms: ${content}`,
          message_history: [], // No previous history since it's the first interaction for this modal
        },
        {
          headers: {
            Authorization: `Bearer jAzSNerksrFfK85So9QvdimVTzckEmeFKGdQw3yD`, // Replace with your Cohere API key
            'Content-Type': 'application/json',
          },
        }
      );
  
      const botResponse = response.data.text.trim();
      setMessages([{ sender: 'bot', text: botResponse }]); // Set the initial bot response in modal
      setIsTyping(false);
    } catch (error) {
      console.error('Error fetching response from Cohere AI:', error);
      setMessages([{ sender: 'bot', text: 'Error generating response. Please try again.' }]);
      setIsTyping(false);
    }
  };
  


  const sendMessage = async (e) => {
    e.preventDefault();
    if (input.trim() === '') return;
  
    const userMessage = { sender: 'user', text: input };
    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setInput('');
    setIsTyping(true);
  
    try {
      const response = await axios.post(
        'https://api.cohere.ai/v1/chat',
        {
          query: input, // User's current input
          message_history: messages.map((msg) => ({
            user_name: msg.sender === 'user' ? 'User' : 'Bot',
            text: msg.text,
          })),
        },
        {
          headers: {
            Authorization: `Bearer jAzSNerksrFfK85So9QvdimVTzckEmeFKGdQw3yD`, // Replace with your API key
            'Content-Type': 'application/json',
          },
        }
      );
  
      const botResponse = response.data.text.trim();
      const botMessage = { sender: 'bot', text: botResponse };
      setMessages((prevMessages) => [...prevMessages, botMessage]); // Append the bot's reply
      setIsTyping(false);
    } catch (error) {
      console.error('Error generating response:', error);
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: 'bot', text: 'Error generating response. Please try again.' },
      ]);
      setIsTyping(false);
    }
  };

  //handles the users prompt 
  const handleInputChange = (e) => {
    setInput(e.target.value);
  };
  //If the user wants to closr the Copilot 
  const handleCloseModal = () => {
    setModalOpen(false);
    setMessages([]);
  };


  return (
    <div style={{backgroundColor: 'rgb(240, 242, 245)', height: '100vh'}}>
      <div className='dashcontent'>
        <div>
          <h2>Current Threat Level- {threatLevel}</h2>
        </div>
        <div className="Threatprogress-bar-container">
          <div
            className={`ThreatBar`}
            style={{ width: getProgressBarWidth() }}
          />
        </div>
        <div className="dashboard-container">
          <div className="stats-box" onClick={() => openModal('Techniques and Processes')}>
            <div className="icon-box black">
              <PiStrategyBold />
            </div>
            <div className="content-box">
              <br></br>
              <br></br>
              <h4>{`Number of Threats: ${Object.values(answers['Techniques and Processes'] || {}).filter(item => item.recommendation !== null).length}  `}</h4>
              <h3>Techniques and Processes</h3>
              <hr className="hrLine" />
              <p className="change-text">Just Updated</p>
            </div>
          </div>
        
          <div className="stats-box" onClick={() => openModal('Accessibility')}>
            <div className="icon-box blue">
              <FaUniversalAccess />
            </div>
            <div className="content-box">
              <br></br>
              <br></br>
              <h4>{`Number of Threats: ${Object.values(answers['Accessibility'] || {}).filter(item => item.recommendation !== null).length}  `}</h4>
              <h3>Accessibility</h3>
              <hr className="hrLine" />
              <p className="change-text">Just Updated</p>
            </div>
          </div>

          {/* Identifiability and Linkability */}
          <div className="stats-box" onClick={() => openModal('Identifiability and Linkability')}>
            <div className="icon-box green">
              <PiIdentificationCardFill />
            </div>
            <div className="content-box">
              <br></br>
              <br></br>
              <h4>{`Number of Threats: ${Object.values(answers['Identifiability and Linkability'] || {}).filter(item => item.recommendation !== null).length}  `}</h4>
              <h3>Identifiability and Linkability</h3>
              <hr className="hrLine" />
              <p className="change-text">Just Updated</p>
            </div>
          </div>
       
          {/* Security */}
          <div className="stats-box" onClick={() => openModal('Security')}>
            <div className="icon-box red">
              <AiFillSecurityScan />
            </div>
            <div className="content-box">
              <br></br>
              <br></br>
              <h4>{`Number of Threats: ${Object.values(answers['Security'] || {}).filter(item => item.recommendation !== null).length} `}</h4>
              <h3>Security</h3>
              <hr className="hrLine" />
              <p className="change-text">Just Updated</p>
            </div>
          </div>
        </div>
        <div className="dashboard-container level2">
          {/* Safety */}
          <div className="stats-box" onClick={() => openModal('Safety')}>
            <div className="icon-box black">
              <AiFillSafetyCertificate />
            </div>
            <div className="content-box">
              <br></br>
              <br></br>
              <h4>{`Number of Threats: ${Object.values(answers['Safety'] || {}).filter(item => item.recommendation !== null).length} `}</h4>
              <h3>Safety</h3>
              <hr className="hrLine" />
              <p className="change-text">Just Updated</p>
            </div>
          </div>

          {/* Unawareness */}
          <div className="stats-box" onClick={() => openModal('Unawareness')}>
            <div className="icon-box blue">
              <ImConfused2 />
            </div>
            <div className="content-box">
              <br></br>
              <br></br>
              <h4>{`Number of Threats: ${Object.values(answers['Unawareness'] || {}).filter(item => item.recommendation !== null).length}  `}</h4>
              <h3>Unawareness</h3>
              <hr className="hrLine" />
              <p className="change-text">Just Updated</p>
            </div>
          </div>

          {/* Ethics and Human Rights */}
          <div className="stats-box" onClick={() => openModal('Ethics and Human Rights')}>
            <div className="icon-box green">
              <HiMiniUserGroup />
            </div>
            <div className="content-box">
              <br></br>
              <br></br>
              <h4>{`Number of Threats: ${Object.values(answers['Ethics and Human Rights'] || {}).filter(item => item.recommendation !== null).length}  `}</h4>
              <h3>Ethics and Human Rights</h3>
              <hr className="hrLine" />
              <p className="change-text">Just Updated</p>
            </div>
          </div>

          {/* Non-Compliance */}
          <div className="stats-box" onClick={() => openModal('Non-Compliance')}>
            <div className="icon-box red">
              <GrCompliance />
            </div>
            <div className="content-box">
              <br></br>
              <br></br>
              <h4>{`Number of Threats: ${Object.values(answers['Non-Compliance'] || {}).filter(item => item.recommendation !== null).length}  `}</h4>
              <h3>Non-Compliance</h3>
              <hr className="hrLine" />
              <p className="change-text">Just Updated</p>
            </div>
          </div>
        </div>
        {/* Modal for displaying the questions with recommendations */}
        <DashModal isOpen={isDashModalOpen} onClose={closeModal} />
      </div>
    </div>
  );
};

    

export default Dashboard;