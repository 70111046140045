import React, { useState, useEffect } from 'react';
import'./ThreatIntelligence.css'
import { MdStore } from "react-icons/md";
import { FaSquare, FaPiggyBank, FaUserClock, FaCoins } from "react-icons/fa";
import {  RiSwordFill  } from "react-icons/ri";
import { IoPersonAdd } from "react-icons/io5";
import { db, auth } from '../Firebase_Config/firebase';
import { collection, query, where, orderBy, limit, getDocs, doc, getDoc } from 'firebase/firestore';
import { Line, Bar } from 'react-chartjs-2';
import 'chart.js/auto'; // This automatically registers all the components from Chart.js
import pattern from 'patternomaly'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import { AiFillSecurityScan, AiFillSafetyCertificate  } from "react-icons/ai";
import { BsShieldFillExclamation } from "react-icons/bs";
import { GiSwordsEmblem, GiPayMoney, GiCash } from "react-icons/gi";
import { SiCyberdefenders } from "react-icons/si";
import { LuCalendarClock } from "react-icons/lu";
import { FaMoneyBillWave } from "react-icons/fa";
import Copyright from '../CopyrightFooter/Copyright';
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { MdOutlineCenterFocusWeak } from "react-icons/md";
import { PiStrategyBold, PiIdentificationCardFill } from "react-icons/pi";
import { FaUniversalAccess } from "react-icons/fa6";
import { ImConfused2 } from "react-icons/im";
import { HiMiniUserGroup } from "react-icons/hi2";
import { GrCompliance } from "react-icons/gr";
import axios from 'axios';





const Collapsible = ({ summaryText, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="collapsible">
      <div className="collapsible-header">
        <div className="arrow" onClick={() => setIsOpen(!isOpen)}>
          <MdOutlineCenterFocusWeak className='AIicon2' />
          {isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
          <span>Threat Name: {summaryText}</span>
        </div>
      </div>
      {isOpen && <div className="collapsible-content">{children}</div>}
    </div>
  );
};

const group1 = 'Collection';
const group2 = 'Credential Access';
const group3 = 'Defense Evasion';
const group4 = 'Discovery';
const group5 = 'Exfiltration';
const group6 = 'Execution';
const group7 = 'Impact';
const group8 = 'Initial Access';
const group9 = 'ML Attack Staging';
const group10 = 'ML Model Access';
const group11 = 'Persistence';
const group12 = 'Privilege Escalation';
const group13 = 'Reconnaissance';
const group14 = 'Resource Development';

const getUserId = () => {
  const user = auth.currentUser;
  return user ? user.uid : null; // Ensure user is logged in
};

const ThreatIntel = () => {
  

  const Threatcontent = [
    {groupName: group1, text: 'Data from Information Repositories', 
        description: `
        <ul>
            <li>Adversaries may leverage information repositories to mine valuable information. Information repositories are tools that allow for storage of information, typically to facilitate collaboration or information sharing between users, and can store a wide variety of data that may aid adversaries in further objectives, or direct access to the target information.</li>
            <li>Information stored in a repository may vary based on the specific instance or environment. Specific common information repositories include SharePoint, Confluence, and enterprise databases such as SQL Server.</li>
        </ul>
        `},
    {groupName: group1, text: 'Data from Local System', 
        description: `
        <ul>
            <li>Adversaries may search local system sources, such as file systems and configuration files or local databases, to find files of interest and sensitive data prior to Exfiltration.</li>
            <li>This can include basic fingerprinting information and sensitive data such as ssh keys.</li>
        </ul>
        `},
    {groupName: group1, text: 'ML Artifact Collection', 
        description: `
        <ul>
            <li>Adversaries may collect ML artifacts for [Exfiltration](/tactics/AML.TA0010) or for use in [ML Attack Staging](/tactics/AML.TA0001).</li>
            <li>ML artifacts include models and datasets as well as other telemetry data produced when interacting with a model.</li>
        </ul>
        `},
    {groupName: group2, text: 'Unsecured Credentials', 
        description: `
        <ul>
            <li>Adversaries may search compromised systems to find and obtain insecurely stored credentials. These credentials can be stored and/or misplaced in many locations on a system, including plaintext files (e.g. bash history), environment variables, operating system, or application-specific repositories (e.g. Credentials in Registry), or other specialized files/artifacts (e.g. private keys).</li>
        </ul>
        `},

    {groupName: group3, text: 'Evade ML Model', 
        description: `
        <ul>
            <li>Adversaries can [Craft Adversarial Data](/techniques/AML.T0043) that prevent a machine learning model from correctly identifying the contents of the data. This technique can be used to evade a downstream task where machine learning is utilized.</li>
            <li>The adversary may evade machine learning based virus/malware detection, or network scanning towards the goal of a traditional cyber attack.</li>
        </ul>
        `},
        {groupName: group7, text: 'Evade ML Model', 
            description: `
            <ul>
                <li>Adversaries can [Craft Adversarial Data](/techniques/AML.T0043) that prevent a machine learning model from correctly identifying the contents of the data. This technique can be used to evade a downstream task where machine learning is utilized.</li>
                <li>The adversary may evade machine learning based virus/malware detection, or network scanning towards the goal of a traditional cyber attack.</li>
            </ul>
            `},
            {groupName: group8, text: 'Evade ML Model', 
                description: `
                <ul>
                    <li>Adversaries can [Craft Adversarial Data](/techniques/AML.T0043) that prevent a machine learning model from correctly identifying the contents of the data. This technique can be used to evade a downstream task where machine learning is utilized.</li>
                    <li>The adversary may evade machine learning based virus/malware detection, or network scanning towards the goal of a traditional cyber attack.</li>
                </ul>
                `},
                

    {groupName: group3, text: 'Direct', 
        description: `
        <ul>
            <li>An adversary may inject prompts directly as a user of the LLM. This type of injection may be used by the adversary to gain a foothold in the system or to misuse the LLM itself, as for example to generate harmful content.</li>
        </ul>
        `},
        {groupName: group8, text: 'Direct', 
            description: `
            <ul>
                <li>An adversary may inject prompts directly as a user of the LLM. This type of injection may be used by the adversary to gain a foothold in the system or to misuse the LLM itself, as for example to generate harmful content.</li>
            </ul>
            `},
            {groupName: group11, text: 'Direct', 
                description: `
                <ul>
                    <li>An adversary may inject prompts directly as a user of the LLM. This type of injection may be used by the adversary to gain a foothold in the system or to misuse the LLM itself, as for example to generate harmful content.</li>
                </ul>
                `},
                {groupName: group12, text: 'Direct', 
                    description: `
                    <ul>
                        <li>An adversary may inject prompts directly as a user of the LLM. This type of injection may be used by the adversary to gain a foothold in the system or to misuse the LLM itself, as for example to generate harmful content.</li>
                    </ul>
                    `},

    {groupName: group3, text: 'Indirect', 
        description: `
        <ul>
            <li>An adversary may inject prompts indirectly via separate data channel ingested by the LLM such as text or multimedia pulled from databases or websites. These malicious prompts may be hidden or obfuscated from the user.</li>
            <li>This type of injection may be used by the adversary to gain a foothold in the system or to target an unwitting user of the system.</li>
        </ul>
        `},
        {groupName: group8, text: 'Indirect', 
            description: `
            <ul>
                <li>An adversary may inject prompts indirectly via separate data channel ingested by the LLM such as text or multimedia pulled from databases or websites. These malicious prompts may be hidden or obfuscated from the user.</li>
                <li>This type of injection may be used by the adversary to gain a foothold in the system or to target an unwitting user of the system.</li>
            </ul>
            `},
            {groupName: group11, text: 'Indirect', 
                description: `
                <ul>
                    <li>An adversary may inject prompts indirectly via separate data channel ingested by the LLM such as text or multimedia pulled from databases or websites. These malicious prompts may be hidden or obfuscated from the user.</li>
                    <li>This type of injection may be used by the adversary to gain a foothold in the system or to target an unwitting user of the system.</li>
                </ul>
                `},
                {groupName: group12, text: 'Indirect', 
                    description: `
                    <ul>
                        <li>An adversary may inject prompts indirectly via separate data channel ingested by the LLM such as text or multimedia pulled from databases or websites. These malicious prompts may be hidden or obfuscated from the user.</li>
                        <li>This type of injection may be used by the adversary to gain a foothold in the system or to target an unwitting user of the system.</li>
                    </ul>
                    `},

    {groupName: group3, text: 'LLM Prompt Injection', 
        description: `
        <ul>
            <li>An adversary may craft malicious prompts as inputs to an LLM that cause the LLM to act in unintended ways. These "prompt injections" are often designed to cause the model to ignore aspects of its original instructions and follow the adversary's instructions instead.</li>
            <li>Prompt Injections can be an initial access vector to the LLM that provides the adversary with a foothold to carry out other steps in their operation.They may be designed to bypass defenses in the LLM, or allow the adversary to issue privileged commands.The effects of a prompt injection can persist throughout an interactive session with an LLM.</li>
            <li>Malicious prompts may be injected directly by the adversary ([Direct](/techniques/AML.T0051.000)) either to leverage the LLM to generate harmful content or to gain a foothold on the system and lead to further effects.</li>
            <li>Prompts may also be injected indirectly when as part of its normal operation the LLM ingests the malicious prompt from another data source ([Indirect](/techniques/AML.T0051.001)). This type of injection can be used by the adversary to a foothold on the system or to target the user of the LLM.</li.
        </ul>
        `},
        {groupName: group8, text: 'LLM Prompt Injection', 
            description: `
            <ul>
                <li>An adversary may craft malicious prompts as inputs to an LLM that cause the LLM to act in unintended ways. These "prompt injections" are often designed to cause the model to ignore aspects of its original instructions and follow the adversary's instructions instead.</li>
                <li>Prompt Injections can be an initial access vector to the LLM that provides the adversary with a foothold to carry out other steps in their operation.They may be designed to bypass defenses in the LLM, or allow the adversary to issue privileged commands.The effects of a prompt injection can persist throughout an interactive session with an LLM.</li>
                <li>Malicious prompts may be injected directly by the adversary ([Direct](/techniques/AML.T0051.000)) either to leverage the LLM to generate harmful content or to gain a foothold on the system and lead to further effects.</li>
                <li>Prompts may also be injected indirectly when as part of its normal operation the LLM ingests the malicious prompt from another data source ([Indirect](/techniques/AML.T0051.001)). This type of injection can be used by the adversary to a foothold on the system or to target the user of the LLM.</li.
            </ul>
            `},
            {groupName: group11, text: 'LLM Prompt Injection', 
                description: `
                <ul>
                    <li>An adversary may craft malicious prompts as inputs to an LLM that cause the LLM to act in unintended ways. These "prompt injections" are often designed to cause the model to ignore aspects of its original instructions and follow the adversary's instructions instead.</li>
                    <li>Prompt Injections can be an initial access vector to the LLM that provides the adversary with a foothold to carry out other steps in their operation.They may be designed to bypass defenses in the LLM, or allow the adversary to issue privileged commands.The effects of a prompt injection can persist throughout an interactive session with an LLM.</li>
                    <li>Malicious prompts may be injected directly by the adversary ([Direct](/techniques/AML.T0051.000)) either to leverage the LLM to generate harmful content or to gain a foothold on the system and lead to further effects.</li>
                    <li>Prompts may also be injected indirectly when as part of its normal operation the LLM ingests the malicious prompt from another data source ([Indirect](/techniques/AML.T0051.001)). This type of injection can be used by the adversary to a foothold on the system or to target the user of the LLM.</li.
                </ul>
                `},
                {groupName: group12, text: 'LLM Prompt Injection', 
                    description: `
                    <ul>
                        <li>An adversary may craft malicious prompts as inputs to an LLM that cause the LLM to act in unintended ways. These "prompt injections" are often designed to cause the model to ignore aspects of its original instructions and follow the adversary's instructions instead.</li>
                        <li>Prompt Injections can be an initial access vector to the LLM that provides the adversary with a foothold to carry out other steps in their operation.They may be designed to bypass defenses in the LLM, or allow the adversary to issue privileged commands.The effects of a prompt injection can persist throughout an interactive session with an LLM.</li>
                        <li>Malicious prompts may be injected directly by the adversary ([Direct](/techniques/AML.T0051.000)) either to leverage the LLM to generate harmful content or to gain a foothold on the system and lead to further effects.</li>
                        <li>Prompts may also be injected indirectly when as part of its normal operation the LLM ingests the malicious prompt from another data source ([Indirect](/techniques/AML.T0051.001)). This type of injection can be used by the adversary to a foothold on the system or to target the user of the LLM.</li.
                    </ul>
                    `},

    {groupName: group3, text: 'LLM Jailbreak', 
        description: `
        <ul>
            <li>An adversary may use a carefully crafted [LLM Prompt Injection](/techniques/AML.T0051) designed to place LLM in a state in which it will freely respond to any user input, bypassing any controls, restrictions, or guardrails placed on the LLM.</li>
            <li>Once successfully jailbroken, the LLM can be used in unintended ways by the adversary.</li>
        </ul>
        `},
        {groupName: group12, text: 'LLM Jailbreak', 
            description: `
            <ul>
                <li>An adversary may use a carefully crafted [LLM Prompt Injection](/techniques/AML.T0051) designed to place LLM in a state in which it will freely respond to any user input, bypassing any controls, restrictions, or guardrails placed on the LLM.</li>
                <li>Once successfully jailbroken, the LLM can be used in unintended ways by the adversary.</li>
            </ul>
            `},
    {groupName: group4, text: 'Discover ML Artifacts', 
        description: `
        <ul>
            <li>Adversaries may search private sources to identify machine learning artifacts that exist on the system and gather information about them. These artifacts can include software stacks, training data management systems, container registries, software repositories, and model zoos. This information can be used to identify targets for further collection, exfiltration, or disruption, and to tailor and improve attacks.</li>
        </ul>
        `},

    {groupName: group4, text: 'Discover ML Model Family', 
        description: `
        <ul>
            <li>Adversaries may discover the general family of model. General information about the model may be revealed in documentation, or the adversary may use carefully constructed examples and analyze the model's responses to categorize it.</li>
            <li>Knowledge of the model family can help the adversary identify means of attacking the model and help tailor the attack.</li>
        </ul>
        `},

    {groupName: group4, text: 'Discover ML Model Ontology', 
        description: `
        <ul>
            <li>Adversaries may discover the ontology of a machine learning model's output space, for example, the types of objects a model can detect.</li>
            <li>The adversary may discovery the ontology by repeated queries to the model, forcing it to enumerate its output space. Or the ontology may be discovered in a configuration file or in documentation about the model.</li>
            <li>The model ontology helps the adversary understand how the model is being used by the victim. It is useful to the adversary in creating targeted attacks.</li>
        </ul>
        `},
    {groupName: group4, text: 'LLM Meta Prompt Extraction', 
        description: `
        <ul>
            <li>An adversary may induce an LLM to reveal its initial instructions, or "meta prompt." Discovering the meta prompt can inform the adversary about the internal workings of the system.</li>
            <li>Prompt engineering is an emerging field that requires expertise and exfiltrating the meta prompt can prompt in order to steal valuable intellectual property.</li>
        </ul>
        `},
        {groupName: group5, text: 'LLM Meta Prompt Extraction', 
            description: `
            <ul>
                <li>An adversary may induce an LLM to reveal its initial instructions, or "meta prompt." Discovering the meta prompt can inform the adversary about the internal workings of the system.</li>
                <li>Prompt engineering is an emerging field that requires expertise and exfiltrating the meta prompt can prompt in order to steal valuable intellectual property.</li>
            </ul>
            `},


    {groupName: group6, text: 'Command and Scripting Interpreter', 
        description: `
        <ul>
            <li>Adversaries may abuse command and script interpreters to execute commands, scripts, or binaries. These interfaces and languages provide ways of interacting with computer systems and are a common feature across many different platforms. Most systems come with some built-in command-line interface and scripting capabilities, for example, macOS and Linux distributions include some flavor of Unix Shell while Windows installations include the Windows Command Shell and PowerShell.</li>
            <li>There are also cross-platform interpreters such as Python, as well as those commonly associated with client applications such as JavaScript and Visual Basic.</li>
            <li>Adversaries may abuse these technologies in various ways as a means of executing arbitrary commands. Commands and scripts can be embedded in Initial Access payloads delivered to victims as lure documents or as secondary payloads downloaded from an existing C2. Adversaries may also execute commands through interactive terminals/shells, as well as utilize various Remote Services in order to achieve remote Execution.</li>
        </ul>
        `},

    {groupName: group6, text: 'Unsafe ML Artifacts', 
        description: `
        <ul>
            <li>Adversaries may develop unsafe ML artifacts that when executed have a deleterious effect. The adversary can use this technique to establish persistent access to systems. These models may be introduced via a [ML Supply Chain Compromise](/techniques/AML.T0010).</li>
            <li>Serialization of models is a popular technique for model storage, transfer, and loading. However, this format without proper checking presents an opportunity for code execution.</li>
        </ul>
        `},

    {groupName: group6, text: 'User Execution', 
        description: `
        <ul>
            <li>An adversary may rely upon specific actions by a user in order to gain execution.</li>
            <li>Users may inadvertently execute unsafe code introduced via [ML Supply Chain Compromise](/techniques/AML.T0010). Users may be subjected to social engineering to get them to execute malicious code by, for example, opening a malicious document file or link.</li>
        </ul>
        `},

    {groupName: group6, text: 'LLM Plugin Compromise', 
        description: `
        <ul>
            <li>Adversaries may use their access to an LLM that is part of a larger system to compromise connected plugins.</li>
            <li>LLMs are often connected to other services or resources via plugins to increase their capabilities.</li>
            <li>Plugins may include integrations with other applications, access to public or private data sources, and the ability to execute code.</li>
            <li>This may allow adversaries to execute API calls to integrated applications or plugins, providing the adversary with increased privileges on the system.</li>
            <li>Adversaries may take advantage of connected data sources to retrieve sensitive information. They may also use an LLM integrated with a command or script interpreter to execute arbitrary instructions.</li>
        </ul>
        `},
        {groupName: group12, text: 'LLM Plugin Compromise', 
            description: `
            <ul>
                <li>Adversaries may use their access to an LLM that is part of a larger system to compromise connected plugins.</li>
                <li>LLMs are often connected to other services or resources via plugins to increase their capabilities.</li>
                <li>Plugins may include integrations with other applications, access to public or private data sources, and the ability to execute code.</li>
                <li>This may allow adversaries to execute API calls to integrated applications or plugins, providing the adversary with increased privileges on the system.</li>
                <li>Adversaries may take advantage of connected data sources to retrieve sensitive information. They may also use an LLM integrated with a command or script interpreter to execute arbitrary instructions.</li>
            </ul>
            `},
    {groupName: group5, text: 'Exfiltration via Cyber Means', 
        description: `
        <ul>
            <li>Adversaries may exfiltrate ML artifacts or other relevant information through traditional cyber means. See the [ATT&CK Exfiltration tactic](https://attack.mitre.org/tactics/TA0010/) for more information.</li>
        </ul>
        `},

    {groupName: group5, text: 'Exfiltration via ML Inference API', 
        description: `
        <ul>
            <li>Adversaries may exfiltrate private information via [ML Model Inference API Access](/techniques/AML.T0040).</li>
            <li>ML Models have been shown leak private information about their training data (e.g. [Infer Training Data Membership](/techniques/AML.T0024.000), [Invert ML Model](/techniques/AML.T0024.001)).</li>
            <li>The model itself may also be extracted ([Extract ML Model](/techniques/AML.T0024.002)) for the purposes of [ML Intellectual Property Theft](/techniques/AML.T0048.004).</li>
            <li>Exfiltration of information relating to private training data raises privacy concerns. Private training data may include personally identifiable information, or other protected data.</li>
        </ul>
        `},

    {groupName: group5, text: 'Extract ML Model', 
        description: `
        <ul>
            <li>Adversaries may extract a functional copy of a private model.By repeatedly querying the victim's [ML Model Inference API Access](/techniques/AML.T0040), the adversary can collect the target model's inferences into a dataset.</li>
            <li>The inferences are used as labels for training a separate model offline that will mimic the behavior and performance of the target model.</li>
            <li>Adversaries may extract the model to avoid paying per query in a machine learning as a service setting. Model extraction is used for [ML Intellectual Property Theft](/techniques/AML.T0048.004).</li>
        </ul>
        `},

    {groupName: group5, text: 'Infer Training Data Membership', 
        description: `
        <ul>
            <li>Adversaries may infer the membership of a data sample in its training set, which raises privacy concerns. Some strategies make use of a shadow model that could be obtained via [Train Proxy via Replication](/techniques/AML.T0005.001), others use statistics of model prediction scores.</li>
            <li>This can cause the victim model to leak private information, such as PII of those in the training set or other forms of protected IP.</li>
        </ul>
        `},

    {groupName: group5, text: 'Invert ML Model', 
        description: `
        <ul>
            <li>Machine learning models' training data could be reconstructed by exploiting the confidence scores that are available via an inference API.</li>
            <li>By querying the inference API strategically, adversaries can back out potentially private information embedded within the training data.</li>
            <li>This could lead to privacy violations if the attacker can reconstruct the data of sensitive features used in the algorithm.</li>
        </ul>
        `},

    {groupName: group5, text: 'LLM Data Leakage', 
        description: `
        <ul>
            <li>Adversaries may craft prompts that induce the LLM to leak sensitive information. This can include private user data or proprietary information.</li>
            <li>The leaked information may come from proprietary training data, data sources the LLM is connected to, or information from other users of the LLM.</li>
        </ul>
        `},
    { groupName: group7, text: 'Cost Harvesting',
        description: `
            <ul>
                <li>Adversaries may target different machine learning services to send useless queries or computationally expensive inputs to increase the cost of running services at the victim organization.</li>
                <li>Sponge examples are a particular type of adversarial data designed to maximize energy consumption and thus operating cost.</li>
            </ul>
        `},
    { groupName: group7, text: 'Denial of ML Service',
        description: `
            <ul>
                <li>Adversaries may target machine learning systems with a flood of requests for the purpose of degrading or shutting down the service.</li>
                <li>Since many machine learning systems require significant amounts of specialized compute, they are often expensive bottlenecks that can become overloaded.</li>
                <li>Adversaries can intentionally craft inputs that require heavy amounts of useless compute from the machine learning system.</li>
            </ul>
        `},
    { groupName: group7, text: 'Erode ML Model Integrity',
        description: `
            <ul>
            <li>Adversaries may degrade the target model's performance with adversarial data inputs to erode confidence in the system over time.</li>
            <li>This can lead to the victim organization wasting time and money both attempting to fix the system and performing the tasks it was meant to automate by hand.</li>
            </ul>
        `},
    { groupName: group7, text: 'External Harms',
        description: `
            <ul>
                <li>Adversaries may abuse their access to a victim system and use its resources or capabilities to further their goals by causing harms external to that system.</li>
                <li>These harms could affect the organization (e.g. Financial Harm, Reputational Harm), its users (e.g. User Harm), or the general public (e.g. Societal Harm).</li>
            </ul>
        `},
    {groupName: group7,text: 'Financial Harm',
        description: `
            <ul>
                <li>Financial harm involves the loss of wealth, property, or other monetary assets due to theft, fraud or forgery, or pressure to provide financial resources to the adversary.</li>
            </ul>
        `},
    {groupName: group7, text: 'ML Intellectual Property Theft',
        description: `
            <ul>
                <li>Adversaries may exfiltrate ML artifacts to steal intellectual property and cause economic harm to the victim organization.</li>
                <li>Proprietary training data is costly to collect and annotate and may be a target for exfiltration and theft.</li>
                <li>MLaaS providers charge for use of their API.An adversary who has stolen a model via [Exfiltration](/tactics/AML.TA0010) or via [Extract ML Model](/techniques/AML.T0024.002) now has unlimited use of that service without paying the owner of the intellectual property.</li>
            </ul>
        `},
    { groupName: group7, text: 'Reputational Harm',
        description: `
            <ul>
                <li>Reputational harm involves a degradation of public perception and trust in organizations. Examples of reputation-harming incidents include scandals or false impersonations.</li>
            </ul>
        `},
    { groupName: group7,text: 'Societal Harm',
        description: `
            <ul>
                <li>Societal harms might generate harmful outcomes that reach either the general public or specific vulnerable groups such as the exposure of children to vulgar content.</li>
            </ul>
        `},
    {groupName: group7, text: 'Spamming ML System with Chaff Data',
        description: `
            <ul>
                <li>Adversaries may spam the machine learning system with chaff data that causes increase in the number of detections.</li>
                <li>This can cause analysts at the victim organization to waste time reviewing and correcting incorrect inferences.</li>
            </ul>
        `},
    {groupName: group7, text: 'User Harm',
        description: `
            <ul>
                <li>User harms may encompass a variety of harm types including financial and reputational that are directed at or felt by individual victims of the attack rather than at the organization level.</li>
            </ul>
        `},
    {groupName: group8, text: 'Data', 
        description: `
            <ul>
                <li>Data is a key vector of supply chain compromise for adversaries. Every machine learning project will require some form of data. Many rely on large open source datasets that are publicly available.</li>
                <li>An adversary could rely on compromising these sources of data. The malicious data could be a result of [Poison Training Data](/techniques/AML.T0020) or include traditional malware.</li>
                <li>An adversary can also target private datasets in the labelling phase. The creation of private datasets will often require the hiring of outside labelling services.</li>
                <li>An adversary can poison a dataset by modifying the labels being generated by the labelling service.</li>
            </ul>
        `},
    {groupName: group8, text: 'Exploit Public-Facing Application', 
        description: `
            <ul>
                <li>Adversaries may attempt to take advantage of a weakness in an Internet-facing computer or program using software, data, or commands to cause unintended or unanticipated behavior.</li>
                <li>The weakness in the system can be a bug, glitch, or design vulnerability. These applications are often websites but can include databases (like SQL), standard services (like SMB or SSH), and any other applications with Internet accessible open sockets, such as web servers and related services.</li>
            </ul>
        `},
    {groupName: group8, text: 'GPU Hardware', 
        description: `
            <ul>
                <li>Most machine learning systems require access to certain specialized hardware, typically GPUs.</li>
                <li>Adversaries can target machine learning systems by specifically targeting the GPU supply chain.</li>
            </ul>
        `},
    {groupName: group8, text: 'ML Software', 
        description: `
            <ul>
                <li>Most machine learning systems rely on a limited set of machine learning frameworks. An adversary could get access to a large number of machine learning systems through a compromise of one of their supply chains.</li>
                <li>Many machine learning projects also rely on other open source implementations of various algorithms. These can also be compromised in a targeted way to get access to specific systems.</li>
            </ul>
        `},
    {groupName: group8, text: 'ML Supply Chain Compromise', 
        description: `
            <ul>
                <li>Adversaries may gain initial access to a system by compromising the unique portions of the ML supply chain.</li>
                <li>This could include [GPU Hardware](/techniques/AML.T0010.000), [Data](/techniques/AML.T0010.002) and its annotations, parts of the ML [ML Software](/techniques/AML.T0010.001) stack, or the [Model](/techniques/AML.T0010.003) itself.</li>
                <li>In some instances, the attacker will need secondary access to fully carry out an attack using compromised components of the supply chain.</li>
            </ul>
        `},
    {groupName: group8, text: 'Model', 
        description: `
            <ul>
                <li>Machine learning systems often rely on open sourced models in various ways. Most commonly, the victim organization may be using these models for fine tuning. These models will be downloaded from an external source and then used as the base for the model as it is tuned on a smaller, private dataset..</li>
                <li>Loading models often requires executing some saved code in the form of a saved model file. These can be compromised with traditional malware, or through some adversarial machine learning techniques.</li>
            </ul>
        `},
    {groupName: group8, text: 'Phishing', 
        description: `
            <ul>
                <li>Adversaries may send phishing messages to gain access to victim systems. All forms of phishing are electronically delivered social engineering. Phishing can be targeted, known as spearphishing. In spearphishing, a specific individual, company, or industry will be targeted by the adversary. More generally, adversaries can conduct non-targeted phishing, such as in mass malware spam campaigns.</li>
                <li>Generative AI, including LLMs that generate synthetic text, visual deepfakes of faces, and audio deepfakes of speech, is enabling adversaries to scale targeted phishing campaigns. LLMs can interact with users via text conversations and can be programmed with a meta prompt to phish for sensitive information. Deepfakes can be use in impersonation as an aid to phishing.</li>
            </ul>
        `},
    {groupName: group8, text: 'Spearphishing via Social Engineering LLM', 
        description: `
            <ul>
                <li>Adversaries may turn LLMs into targeted social engineers. LLMs are capable of interacting with users via text conversations.</li>
                <li>They can be instructed by an adversary to seek sensitive information from a user and act as effective social engineers. They can be targeted towards particular personas defined by the adversary.</li>
                <li>This allows adversaries to scale spearphishing efforts and target individuals to reveal private information such as credentials to privileged systems.</li>
            </ul>
        `},
    {groupName: group8, text: 'Valid Accounts', 
        description: `
            <ul>
                <li>Adversaries may obtain and abuse credentials of existing accounts as a means of gaining initial access.</li>
                <li>Credentials may take the form of usernames and passwords of individual user accounts or API keys that provide access to various ML resources and services.</li>
                <li>Compromised credentials may provide access to additional ML artifacts and allow the adversary to perform [Discover ML Artifacts](/techniques/AML.T0007). Compromised credentials may also grant an adversary increased privileges such as write access to ML artifacts used during development or production.</li>
            </ul>
        `},
    {groupName: group9, text: 'Black-Box Optimization', 
        description: `
            <ul>
                <li>In Black-Box attacks, the adversary has black-box (i.e. [ML Model Inference API Access](/techniques/AML.T0040) via API access) access to the target model. With black-box attacks, the adversary may be using an API that the victim is monitoring.</li>
                <li>These attacks are generally less effective and require more inferences than [White-Box Optimization](/techniques/AML.T0043.000) attacks, but they require much less access.</li>
            </ul>
        `},
    {groupName: group9, text: 'Black-Box Transfer', 
        description: `
            <ul>
                <li>In Black-Box Transfer attacks, the adversary uses one or more proxy models (trained via [Create Proxy ML Model](/techniques/AML.T0005) or [Train Proxy via Replication](/techniques/AML.T0005.001)) they have full access to and are representative of the target model.</li>
                <li>The adversary uses [White-Box Optimization](/techniques/AML.T0043.000) on the proxy models to generate adversarial examples.If the set of proxy models are close enough to the target model, the adversarial example should generalize from one to another.</li>
                <li>This means that an attack that works for the proxy models will likely then work for the target model. If the adversary has [ML Model Inference API Access](/techniques/AML.T0040), they may use [Verify Attack](/techniques/AML.T0042) to confirm the attack is working and incorporate that information into their training process.</li>
            </ul>
        `},
    {groupName: group9, text: 'Craft Adversarial Data', 
        description: `
            <ul>
                <li>Adversarial data are inputs to a machine learning model that have been modified such that they cause the adversary's desired effect in the target model. Effects can range from misclassification, to missed detections, to maximizing energy consumption.</li>
                <li>Typically, the modification is constrained in magnitude or location so that a human still perceives the data as if it were unmodified, but human perceptibility may not always be a concern depending on the adversary's intended effect. For example, an adversarial input for an image classification task is an image the machine learning model would misclassify, but a human would still recognize as containing the correct class.</li>
                <li>Depending on the adversary's knowledge of and access to the target model, the adversary may use different classes of algorithms to develop the adversarial example such as [White-Box Optimization](/techniques/AML.T0043.000), [Black-Box Optimization](/techniques/AML.T0043.001), [Black-Box Transfer](/techniques/AML.T0043.002), or [Manual Modification](/techniques/AML.T0043.003).</li>
                <li>The adversary may [Verify Attack](/techniques/AML.T0042) their approach works if they have white-box or inference API access to the model. This allows the adversary to gain confidence their attack is effective "live" environment where their attack may be noticed. They can then use the attack at a later time to accomplish their goals.</li>
                <li>An adversary may optimize adversarial examples for [Evade ML Model](/techniques/AML.T0015), or to [Erode ML Model Integrity](/techniques/AML.T0031).</li>
            </ul>
        `},
    {groupName: group9, text: 'Create Proxy ML Model', 
        description: `
            <ul>
                <li>Adversaries may obtain models to serve as proxies for the target model used at the victim organization. Proxy models are used to simulate complete access to the target model in a fully offline manner.</li>
                <li>They may train models from representative datasets, replicate models from victim inference APIs, or use available pre-trained models.</li>
            </ul>
        `},
    {groupName: group9, text: 'Insert Backdoor Trigger', 
        description: `
            <ul>
                <li>The adversary may add a perceptual trigger into inference data. The trigger may be imperceptible or non-obvious to humans. This technique is used in conjunction with [Poison ML Model](/techniques/AML.T0018.000) and allows the adversary to produce their desired effect in the target model.</li>
            </ul>
        `},
    {groupName: group9, text: 'Manual Modification', 
        description: `
            <ul>
                <li>Adversaries may manually modify the input data to craft adversarial data. They may use their knowledge of the target model to modify parts of the data they suspect helps the model in performing its task. The adversary may use trial and error until they are able to verify they have a working adversarial input.</li>
            </ul>
        `},
    {groupName: group9, text: 'Train Proxy via Gathered ML Artifacts', 
        description: `
            <ul>
                <li>Proxy models may be trained from ML artifacts (such as data, model architectures, and pre-trained models) that are representative of the target model gathered by the adversary. This can be used to develop attacks that require higher levels of access than the adversary has available or as a means to validate pre-existing attacks without interacting with the target model.</li>
            </ul>
        `},
    {groupName: group9, text: 'Train Proxy via Replication', 
        description: `
            <ul>
                <li>Adversaries may replicate a private model. By repeatedly querying the victim's [ML Model Inference API Access](/techniques/AML.T0040), the adversary can collect the target model's inferences into a dataset. The inferences are used as labels for training a separate model offline that will mimic the behavior and performance of the target model.</li>
                <li>A replicated model that closely mimic's the target model is a valuable resource in staging the attack. The adversary can use the replicated model to [Craft Adversarial Data](/techniques/AML.T0043) for various purposes (e.g. [Evade ML Model](/techniques/AML.T0015), [Spamming ML System with Chaff Data](/techniques/AML.T0046)).</li>
            </ul>
        `},
    {groupName: group9, text: 'Use Pre-Trained Model', 
        description: `
            <ul>
                <li>Adversaries may use an off-the-shelf pre-trained model as a proxy for the victim model to aid in staging the attack.</li>
            </ul>
        `},
    {groupName: group9, text: 'Verify Attack', 
        description: `
            <ul>
                <li>Adversaries can verify the efficacy of their attack via an inference API or access to an offline copy of the target model. This gives the adversary confidence that their approach works and allows them to carry out the attack at a later time of their choosing.</li>
                <li>The adversary may verify the attack once but use it against many edge devices running copies of the target model. The adversary may verify their attack digitally, then deploy it in the [Physical Environment Access](/techniques/AML.T0041) at a later time.</li>
            </ul>
        `},
    {groupName: group9, text: 'White-Box Optimization', 
        description: `
            <ul>
                <li>In White-Box Optimization, the adversary has full access to the target model and optimizes the adversarial example directly. Adversarial examples trained this way are most effective against the target model.</li>
            </ul>
        `},
    {groupName: group9, text: 'Backdoor ML Model', 
        description: `
            <ul>
                <li>Adversaries may introduce a backdoor into a ML model that operates as expected under typical conditions but will produce the adversary's desired output when a trigger is introduced to the input data.</li>
                <li>A backdoored model provides the adversary with a persistent artifact on the victim system. The embedded vulnerability is typically activated at a later time by data samples with an [Insert Backdoor Trigger](/techniques/AML.T0043.004)</li>
            </ul>
        `},
        {groupName: group11, text: 'Backdoor ML Model', 
            description: `
                <ul>
                    <li>Adversaries may introduce a backdoor into a ML model that operates as expected under typical conditions but will produce the adversary's desired output when a trigger is introduced to the input data.</li>
                    <li>A backdoored model provides the adversary with a persistent artifact on the victim system. The embedded vulnerability is typically activated at a later time by data samples with an [Insert Backdoor Trigger](/techniques/AML.T0043.004)</li>
                </ul>
            `},
    {groupName: group9, text: 'Inject Payload', 
        description: `
            <ul>
                <li>Adversaries may introduce a backdoor into a model by injecting a payload into the model file. The payload detects the presence of the trigger and bypasses the model, instead producing the adversary's desired output.</li>
            </ul>
        `},
        {groupName: group11, text: 'Inject Payload', 
            description: `
                <ul>
                    <li>Adversaries may introduce a backdoor into a model by injecting a payload into the model file. The payload detects the presence of the trigger and bypasses the model, instead producing the adversary's desired output.</li>
                </ul>
            `},
    {groupName: group9, text: 'Poison ML Model', 
        description: `
            <ul>
                <li>Adversaries may introduce a backdoor by training the model poisoned data, or by interfering with its training process. The model learns to associate an adversary-defined trigger with the adversary's desired output.</li>
            </ul>
        `},
        {groupName: group11, text: 'Poison ML Model', 
            description: `
                <ul>
                    <li>Adversaries may introduce a backdoor by training the model poisoned data, or by interfering with its training process. The model learns to associate an adversary-defined trigger with the adversary's desired output.</li>
                </ul>
            `},
    {groupName: group10, text: "Full ML Model Access", 
        description: `
            <ul>
                <li>Adversaries may gain full "white-box" access to a machine learning model. This means the adversary has complete knowledge of the model architecture, its parameters, and class ontology.</li>
                <li>They may exfiltrate the model to [Craft Adversarial Data](/techniques/AML.T0043) and [Verify Attack](/techniques/AML.T0042) in an offline where it is hard to detect their behaviour.</li>
            </ul>
        `},
    {groupName: group10, text: "ML Model Inference API Access", 
        description: `
            <ul>
                <li>Adversaries may gain access to a model via legitimate access to the inference API.</li>
                <li>Inference API access can be a source of information to the adversary ([Discover ML Model Ontology](/techniques/AML.T0013), [Discover ML Model Family](/techniques/AML.T0014)), a means of staging the attack ([Verify Attack](/techniques/AML.T0042), [Craft Adversarial Data](/techniques/AML.T0043)), or for introducing data to the target system for Impact ([Evade ML Model](/techniques/AML.T0015), [Erode ML Model Integrity](/techniques/AML.T0031)).</li>
            </ul>
        `},
    {groupName: group10, text: "ML-Enabled Product or Service", 
        description: `
            <ul>
                <li>Adversaries may use a product or service that uses machine learning under the hood to gain access to the underlying machine learning model. This type of indirect model access may reveal details of the ML model or its inferences in logs or metadata.</li>
            </ul>
        `},
    {groupName: group10, text: "Physical Environment Access", 
        description: `
            <ul>
                <li>In addition to the attacks that take place purely in the digital domain, adversaries may also exploit the physical environment for their attacks.</li>
                <li>If the model is interacting with data collected from the real world in some way, the adversary can influence the model through access to wherever the data is being collected. By modifying the data in the collection process, the adversary can perform modified versions of attacks designed for digital access.</li>
            </ul>
        `},
    {groupName: group11, text: "Poison Training Data", 
        description: `
            <ul>
                <li>Adversaries may attempt to poison datasets used by a ML model by modifying the underlying data or its labels. This allows the adversary to embed vulnerabilities in ML models trained on the data that may not be easily detectable. Data poisoning attacks may or may not require modifying the labels.</li>
                <li>The embedded vulnerability is activated at a later time by data samples with an [Insert Backdoor Trigger](/techniques/AML.T0043.004)</li>
                <li>Poisoned data can be introduced via [ML Supply Chain Compromise](/techniques/AML.T0010) or the data may be poisoned after the adversary gains [Initial Access](/tactics/AML.TA0004) to the system.</li>
            </ul>
        `},
    {groupName: group13, text: "Active Scanning", 
        description: `
            <ul>
                <li>An adversary may probe or scan the victim system to gather information for targeting. This is distinct from other reconnaissance techniques that do not involve direct interaction with the victim system.</li>
            </ul>
        `},
    {groupName: group13, text: "Journals and Conference Proceedings", 
        description: `
            <ul>
                <li>Many of the publications accepted at premier machine learning conferences and journals come from commercial labs. Some journals and conferences are open access, others may require paying for access or a membership. These publications will often describe in detail all aspects of a particular approach for reproducibility. This information can be used by adversaries to implement the paper.</li>
            </ul>
        `},
    {groupName: group13, text: "Pre-Print Repositories", 
        description: `
            <ul>
                <li>Pre-Print repositories, such as arXiv, contain the latest academic research papers that haven't been peer-reviewed. They may contain research notes, or technical reports that aren't typically published in journals or conference proceedings. Pre-print repositories also serve as a central location to share papers that have been accepted to journals. Searching pre-print repositories provides adversaries with a relatively up-to-date view of what researchers in the victim organization are working on.</li>
            </ul>
        `},
    {groupName: group13, text: "Search Application Repositories", 
        description: `
            <ul>
                <li>Adversaries may search open application repositories during targeting. Examples of these include Google Play, the iOS App Store, the macOS App Store, and the Microsoft Store. Adversaries may craft search queries seeking applications that contain ML-enabled components. Frequently, the next step is to [Acquire Public ML Artifacts](/techniques/AML.T0002).</li>
            </ul>
        `},
    {groupName: group13, text: "Search Victim-Owned Websites", 
        description: `
            <ul>
                <li>Adversaries may search websites owned by the victim for information that can be used during targeting. Victim-owned websites may contain technical details about their ML-enabled products or services.</li>
                <li>Victim-owned websites may contain a variety of details, including names of departments/divisions, physical locations, and data about key employees such as names, roles, and contact info. These sites may also have details highlighting business operations and relationships.</li>
                <li>Adversaries may search victim-owned websites to gather actionable information. This information may help adversaries tailor their attacks (e.g. [Adversarial ML Attacks](/techniques/AML.T0017.000) or [Manual Modification](/techniques/AML.T0043.003)).</li>
                <li>Information from these sources may reveal opportunities for other forms of reconnaissance (e.g. [Search for Victim's Publicly Available Research Materials](/techniques/AML.T0000) or [Search for Publicly Available Adversarial Vulnerability Analysis](/techniques/AML.T0001))</li>
            </ul>
        `},
    {groupName: group13, text: "Search for Publicly Available Adversarial Vulnerability Analysis", 
        description: `
            <ul>
                <li>Much like the [Search for Victim's Publicly Available Research Materials](/techniques/AML.T0000), there is often ample research available on the vulnerabilities of common models. </li>
                <li>Once a target has been identified, an adversary will likely try to identify any pre-existing work that has been done for this class of models.</li>
                <li>This will include not only reading academic papers that may identify the particulars of a successful attack, but also identifying pre-existing implementations of those attacks. The adversary may obtain [Adversarial ML Attack Implementations](/techniques/AML.T0016.000) or develop their own [Adversarial ML Attacks](/techniques/AML.T0017.000) if necessary.</li>
            </ul>
        `},
    {groupName: group13, text: "Search for Victim's Publicly Available Research Materials", 
        description: `
            <ul>
                <li>Adversaries may search publicly available research to learn how and where machine learning is used within a victim organization.</li>
                <li>The adversary can use this information to identify targets for attack, or to tailor an existing attack to make it more effective. Organizations often use open source model architectures trained on additional proprietary data in production. Knowledge of this underlying architecture allows the adversary to craft more realistic proxy models ([Create Proxy ML Model](/techniques/AML.T0005)). An adversary can search these resources for publications for authors employed at the victim organization</li>
                <li>Research materials may exist as academic papers published in [Journals and Conference Proceedings](/techniques/AML.T0000.000), or stored in [Pre-Print Repositories](/techniques/AML.T0000.001), as well as [Technical Blogs](/techniques/AML.T0000.002).</li>
            </ul>
        `},
    {groupName: group13, text: "Technical Blogs", 
        description: `
            <ul>
                <li>Research labs at academic institutions and Company R&D divisions often have blogs that highlight their use of machine learning and its application to the organizations unique problems.</li>
                <li>Individual researchers also frequently document their work in blogposts. An adversary may search for posts made by the target victim organization or its employees. In comparison to [Journals and Conference Proceedings](/techniques/AML.T0000.000) and [Pre-Print Repositories](/techniques/AML.T0000.001) this material will often contain more practical aspects of the machine learning system</li>
                <li>This could include underlying technologies and frameworks used, and possibly some information about the API access and use case. This will help the adversary better understand how that organization is using machine learning internally and the details of their approach that could aid in tailoring an attack.</li>
            </ul>
        `},
    {groupName: group14, text: "Acquire Infrastructure", 
        description: `
        <ul>
            <li>Adversaries may buy, lease, or rent infrastructure for use throughout their operation. A wide variety of infrastructure exists for hosting and orchestrating adversary operations. Infrastructure solutions include physical or cloud servers, domains, mobile devices, and third-party web services. Free resources may also be used, but they are typically limited.</li>
            <li>Use of these infrastructure solutions allows an adversary to stage, launch, and execute an operation. Solutions may help adversary operations blend in with traffic that is seen as normal, such as contact to third-party web services. Depending on the implementation, adversaries may use infrastructure that makes it difficult to physically tie back to them as well as utilize infrastructure that can be rapidly provisioned, modified, and shut down.</li>
        </ul>
        `
    },
    {groupName: group14, text: "Acquire Public ML Artifacts", 
        description: `
        <ul>
            <li>Adversaries may search public sources, including cloud storage, public-facing services, and software or data repositories, to identify machine learning artifacts. These machine learning artifacts may include the software stack used to train and deploy models, training and testing data, model configurations, and parameters. An adversary will be particularly interested in artifacts hosted by or associated with the victim organization as they may represent what that organization uses in a production environment.</li>
            <li>Adversaries may identify artifact repositories via other resources associated with the victim organization (e.g. [Search Victim-Owned Websites](/techniques/AML.T0003) or [Search for Victim's Publicly Available Research Materials](/techniques/AML.T0000)). These ML artifacts often provide adversaries with details of the ML task and approach.</li>
            <li>ML artifacts can aid in an adversary's ability to [Create Proxy ML Model](/techniques/AML.T0005). If these artifacts include pieces of the actual model in production, they can be used to directly [Craft Adversarial Data](/techniques/AML.T0043). Acquiring some artifacts requires registration (providing user details such email/name), AWS keys, or written requests, and may require the adversary to [Establish Accounts](/techniques/AML.T0021).</li>
            <li>Artifacts might be hosted on victim-controlled infrastructure, providing the victim with some information on who has accessed that data.</li>
        </ul>
        `},
    {groupName: group14, text: "Adversarial ML Attack Implementations", 
        description: `
        <ul>
            <li>Adversaries may search for existing open-source implementations of machine learning attacks. The research community often publishes their code for reproducibility and to further future research. Libraries intended for research purposes, such as CleverHans, the Adversarial Robustness Toolbox, and FoolBox, can be weaponized by an adversary. Adversaries may also obtain and use tools that were not originally designed for adversarial ML attacks as part of their attack.</li>
        </ul>
        `},
    {groupName: group14, text: "Adversarial ML Attacks", 
        description: `
        <ul>
            <li>Adversaries may develop their own adversarial attacks. They may leverage existing libraries as a starting point ([Adversarial ML Attack Implementations](/techniques/AML.T0016.000)). They may implement ideas described in public research papers or develop custom made attacks for the victim model.</li>
        </ul>
        `},
    {groupName: group14, text: "Consumer Hardware", 
        description: `
        <ul>
            <li>Adversaries may acquire consumer hardware to conduct their attacks. Owning the hardware provides the adversary with complete control of the environment. These devices can be hard to trace.</li>
        </ul>
        `},
    {groupName: group14, text: "Datasets", 
        description: `
        <ul>
            <li>Adversaries may collect public datasets to use in their operations.</li>
            <li>Datasets used by the victim organization or datasets that are representative of the data used by the victim organization may be valuable to adversaries. Datasets can be stored in cloud storage, or on victim-owned websites. Some datasets require the adversary to [Establish Accounts](/techniques/AML.T0021) for access.</li>
            <li>Acquired datasets help the adversary advance their operations, stage attacks, and tailor attacks to the victim organization.</li>
        </ul>
        `},
    {groupName: group14, text: "Develop Capabilities", 
        description: `
        <ul>
            <li>Adversaries may develop their own capabilities to support operations. This process encompasses identifying requirements, building solutions, and deploying capabilities. Capabilities used to support attacks on ML systems are not necessarily ML-based themselves. Examples include setting up websites with adversarial information or creating Jupyter notebooks with obfuscated exfiltration code.</li>
        </ul>
        `},
    {groupName: group14, text: "Establish Accounts", 
        description: `
        <ul>
            <li>Adversaries may create accounts with various services for use in targeting, to gain access to resources needed in [ML Attack Staging](/tactics/AML.TA0001), or for victim impersonation.</li>
        </ul>
        `},
    {groupName: group14, text: "ML Development Workspaces", 
        description: `
        <ul>
            <li>Developing and staging machine learning attacks often requires expensive compute resources. Adversaries may need access to one or many GPUs in order to develop an attack. They may try to anonymously use free resources such as Google Collaboratory, or cloud resources such as AWS, Azure, or Google Cloud as an efficient way to stand up temporary resources to conduct operations. Multiple workspaces may be used to avoid detection.</li>
        </ul>
        `},
    {groupName: group14, text: "Models", 
        description: `
        <ul>
            <li>Adversaries may acquire public models to use in their operations. Adversaries may seek models used by the victim organization or models that are representative of those used by the victim organization. Representative models may include model architectures or pre-trained models which define the architecture as well as model parameters from training on a dataset.</li>
            <li>The adversary may search public sources for common model architecture configuration file formats such as YAML or Python configuration files, and common model storage file formats such as ONNX (.onnx), HDF5 (.h5), Pickle (.pkl), PyTorch (.pth), or TensorFlow (.pb, .tflite).</li>
            <li>Acquired models are useful in advancing the adversary's operations and are frequently used to tailor attacks to the victim model.</li>
        </ul>
        `},
    {groupName: group14, text: "Obtain Capabilities", 
        description: `
        <ul>
            <li>Adversaries may search for and obtain software capabilities for use in their operations. Capabilities may be specific to ML-based attacks [Adversarial ML Attack Implementations](/techniques/AML.T0016.000) or generic software tools repurposed for malicious intent ([Software Tools](/techniques/AML.T0016.001)). In both instances, an adversary may modify or customize the capability to aid in targeting a particular ML system.</li>
        </ul>
        `},
    {groupName: group14, text: "Publish Poisoned Datasets", 
        description: `
        <ul>
            <li>Adversaries may [Poison Training Data](/techniques/AML.T0020) and publish it to a public location. The poisoned dataset may be a novel dataset or a poisoned variant of an existing open source dataset.This data may be introduced to a victim system via [ML Supply Chain Compromise](/techniques/AML.T0010).</li>
        </ul>
        `},
    {groupName: group14, text: "Software Tools", 
        description: `
        <ul>
            <li>Adversaries may search for and obtain software tools to support their operations. Software designed for legitimate use may be repurposed by an adversary for malicious intent. An adversary may modify or customize software tools to achieve their purpose. Software tools used to support attacks on ML systems are not necessarily ML-based themselves.</li>
        </ul>
        `}
]

    const [selectedGroup, setSelectedGroup] = useState(null);
    const [isModalOpen, setModalOpen] = useState(false);
    const [isDashModalOpen, setisDashModalOpen] = useState(false);
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const [isTyping, setIsTyping] = useState(false);
    const [sectorLoaded, setSectorLoaded] = useState(false);

    const Modal = ({ isOpen, onClose, messages, isTyping, input, handleInputChange, sendMessage }) => {
        if (!isOpen) return null;
    
        return (
        <div className="AicontentPop">
            <div className="AIContainerBoxPop">
            <div className="AiheaderPop">
                <h1 className="AIh1">Threat Modelling Co-Pilot</h1>
                </div>
            <div className="chatbot-containerPop">
                <div className="chat-messages">
                {messages.map((msg, idx) => (
                    <div key={idx} className={`message ${msg.sender}`}>
                    {msg.text}
                    </div>
                ))}
                {isTyping && <div className="typing-indicator">Typing...</div>}
                </div>
                <div className="inputBox">
                <form onSubmit={sendMessage}>
                    <input
                    className="promptInput"
                    type="text"
                    value={input}
                    onChange={handleInputChange}
                    placeholder="Ask a question..."
                    />
                    <button className="aibutton" type="submit">
                    <svg height="24" width="24" fill="#FFFFFF" viewBox="0 0 24 24" data-name="Layer 1" id="Layer_1" class="sparkle">
                        <path d="M10,21.236,6.755,14.745.264,11.5,6.755,8.255,10,1.764l3.245,6.491L19.736,11.5l-6.491,3.245ZM18,21l1.5,3L21,21l3-1.5L21,18l-1.5-3L18,18l-3,1.5ZM19.333,4.667,20.5,7l1.167-2.333L24,3.5,21.667,2.333,20.5,0,19.333,2.333,17,3.5Z"></path>
                    </svg>
                    <span className="aitext">Answer</span>
                    </button>
                </form>
                <button className='closeButton' onClick={onClose}>Close</button>
                </div>
            </div>
            
            </div>
        </div>
        );
    };
 

  // Modal component
  const DashModal = ({ isOpen, onClose, groupContent }) => {
    if (!isOpen || !groupContent || groupContent.length === 0) return null; // Return null if modal is closed or no content
  
    return (
      <div className="dashModal">
        <div className='dashModalContent'>
          <div className="dashModalBox">
            <h2>{groupContent[0].groupName}</h2> {/* Display group name */}
            {groupContent.map((item, index) => (
              <Collapsible key={index} summaryText={item.text}>
                <div className='AsessmentContainerBox'>
                    <table className="assessment-table">
                        <thead>
                            <tr>
                                <th className='header-cell'>Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                <div className="cohere-description" onClick={() => handleOpenModal(item.description)} dangerouslySetInnerHTML={{ __html: item.description }}></div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
              </Collapsible>
            ))}
            <Modal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                messages={messages}
                isTyping={isTyping}
                input={input}
                handleInputChange={handleInputChange}
                sendMessage={sendMessage}
                />
            <button className='closeButton' onClick={onClose}>Close</button>
          </div>
        </div>
      </div>
    );
  };

  const handleOpenModal = async (content) => {
    try {
      setModalOpen(true);
      setIsTyping(true);
  
      // Call Cohere chat API instead of generate API
      const response = await axios.post(
        'https://api.cohere.ai/v1/chat',
        {
          query: `Explain this in non-technical terms: ${content}`,
          message_history: [], // No previous history since it's the first interaction for this modal
        },
        {
          headers: {
            Authorization: `Bearer jAzSNerksrFfK85So9QvdimVTzckEmeFKGdQw3yD`, // Replace with your Cohere API key
            'Content-Type': 'application/json',
          },
        }
      );
  
      const botResponse = response.data.text.trim();
      setMessages([{ sender: 'bot', text: botResponse }]); // Set the initial bot response in modal
      setIsTyping(false);
    } catch (error) {
      console.error('Error fetching response from Cohere AI:', error);
      setMessages([{ sender: 'bot', text: 'Error generating response. Please try again.' }]);
      setIsTyping(false);
    }
  };
  


  const sendMessage = async (e) => {
    e.preventDefault();
    if (input.trim() === '') return;
  
    const userMessage = { sender: 'user', text: input };
    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setInput('');
    setIsTyping(true);
  
    try {
      const response = await axios.post(
        'https://api.cohere.ai/v1/chat',
        {
          query: input, // User's current input
          message_history: messages.map((msg) => ({
            user_name: msg.sender === 'user' ? 'User' : 'Bot',
            text: msg.text,
          })),
        },
        {
          headers: {
            Authorization: `Bearer jAzSNerksrFfK85So9QvdimVTzckEmeFKGdQw3yD`, // Replace with your API key
            'Content-Type': 'application/json',
          },
        }
      );
  
      const botResponse = response.data.text.trim();
      const botMessage = { sender: 'bot', text: botResponse };
      setMessages((prevMessages) => [...prevMessages, botMessage]); // Append the bot's reply
      setIsTyping(false);
    } catch (error) {
      console.error('Error generating response:', error);
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: 'bot', text: 'Error generating response. Please try again.' },
      ]);
      setIsTyping(false);
    }
  };

  //handles the users prompt 
  const handleInputChange = (e) => {
    setInput(e.target.value);
  };
  //If the user wants to closr the Copilot 
  const handleCloseModal = () => {
    setModalOpen(false);
    setMessages([]);
  };

  // Handle modal opening
  const openModal = (group) => {
    console.log(`Opening modal for: ${group}`); // Debugging
    setSelectedGroup(group);
    setisDashModalOpen(true);
  };

  const closeModal = () => {
    setisDashModalOpen(false);
    setSelectedGroup(null);
  };
  const groupContent = Threatcontent.filter(content => content.groupName === selectedGroup);

  return (
    <div style={{backgroundColor: 'rgb(240, 242, 245)', height: '100%'}}>
      <div className='dashcontent'>
        <h1>Threat Intelligence</h1>
        <h3>Threat Categories:</h3>
        <div className="dashboard-container">
          <div className="ThreatStats-box" onClick={() => openModal(group1)}>
            <div className="ThreatIcon-box black">
              <PiStrategyBold />
            </div>
            <div className="ThreatContent-box">
              <h4>Collection</h4>
              <h3>{`Number of Threats: 3 `}</h3>
              <hr className="ThreatHrLine" />
              <p className="change-text">Just Updated</p>
            </div>
          </div>
        
          <div className="ThreatStats-box" onClick={() => openModal(group2)}>
            <div className="ThreatIcon-box blue">
              <FaUniversalAccess />
            </div>
            <div className="ThreatContent-box">
              <h4>Credential Access</h4>
              <h3>{`Number of Threats: 1 `}</h3>
              <hr className="ThreatHrLine" />
              <p className="change-text">Just Updated</p>
            </div>
          </div>

          {/* Identifiability and Linkability */}
          <div className="ThreatStats-box" onClick={() => openModal(group3)}>
            <div className="ThreatIcon-box green">
              <PiIdentificationCardFill />
            </div>
            <div className="ThreatContent-box">
              <h4> Defence Evasion</h4>
              <h3>{`Number of Threats: 5`}</h3>
              <hr className="ThreatHrLine" />
              <p className="change-text">Just Updated</p>
            </div>
          </div>
       
          {/* Security */}
          <div className="ThreatStats-box" onClick={() => openModal(group4)}>
            <div className="ThreatIcon-box red">
              <AiFillSecurityScan />
            </div>
            <div className="ThreatContent-box">
              <h4>Discovery</h4>
              <h3>{`Number of Threats: 4 `}</h3>
              <hr className="ThreatHrLine" />
              <p className="change-text">Just Updated</p>
            </div>
          </div>
        </div>
        <div className="dashboard-container level2">
          {/* Safety */}
          <div className="ThreatStats-box" onClick={() => openModal(group5)}>
            <div className="ThreatIcon-box black">
              <AiFillSafetyCertificate />
            </div>
            <div className="ThreatContent-box">
              <h4>Exfiltration</h4>
              <h3>{`Number of Threats: 7 `}</h3>
              <hr className="ThreatHrLine" />
              <p className="change-text">Just Updated</p>
            </div>
          </div>

          {/* Unawareness */}
          <div className="ThreatStats-box" onClick={() => openModal(group6)}>
            <div className="ThreatIcon-box blue">
              <ImConfused2 />
            </div>
            <div className="ThreatContent-box">
              <h4>Execution</h4>
              <h3>{`Number of Threats: 4  `}</h3>
              <hr className="ThreatHrLine" />
              <p className="change-text">Just Updated</p>
            </div>
          </div>

          {/* Ethics and Human Rights */}
            <div className="ThreatStats-box" onClick={() => openModal(group7)}>
                <div className="ThreatIcon-box green">
                    <HiMiniUserGroup />
                </div>
                <div className="ThreatContent-box">
                    <h4>Impact</h4>
                    <h3>{`Number of Threats: 11 `}</h3>
                    <hr className="ThreatHrLine" />
                    <p className="change-text">Just Updated</p>
                </div>
            </div>

          {/* Non-Compliance */}
          <div className="ThreatStats-box" onClick={() => openModal(group8)}>
                <div className="ThreatIcon-box red">
                    <GrCompliance />
                </div>
                <div className="ThreatContent-box">
                    <h4>Initial Access</h4>
                    <h3>{`Number of Threats: 13 `}</h3>
                    <hr className="ThreatHrLine" />
                    <p className="change-text">Just Updated</p>
                </div>
          </div>
        </div>
        <div className="dashboard-container level2">
          {/* Safety */}
          <div className="ThreatStats-box" onClick={() => openModal(group9)}>
                <div className="ThreatIcon-box black">
                    <AiFillSafetyCertificate />
                </div>
                <div className="ThreatContent-box">
                    <h4>ML Attack Staging</h4>
                    <h3>{`Number of Threats: 14 `}</h3>
                    <hr className="ThreatHrLine" />
                    <p className="change-text">Just Updated</p>
                </div>
          </div>

          {/* Unawareness */}
          <div className="ThreatStats-box" onClick={() => openModal(group10)}>
                <div className="ThreatIcon-box blue">
                    <ImConfused2 />
                </div>
                <div className="ThreatContent-box">
                    <h4>ML Model Access </h4>
                    <h3>{`Number of Threats: 4  `}</h3>
                    <hr className="ThreatHrLine" />
                    <p className="change-text">Just Updated</p>
                </div>
          </div>

          {/* Ethics and Human Rights */}
          <div className="ThreatStats-box" onClick={() => openModal(group11)}>
                <div className="ThreatIcon-box green">
                    <HiMiniUserGroup />
                </div>
                <div className="ThreatContent-box">
                    <h4>Persistence</h4>
                    <h3>{`Number of Threats: 7 `}</h3>
                    <hr className="ThreatHrLine" />
                    <p className="change-text">Just Updated</p>
                </div>
          </div>

          <div className="ThreatStats-box" onClick={() => openModal(group12)}>
                <div className="ThreatIcon-box red">
                    <GrCompliance />
                </div>
                <div className="ThreatContent-box">
                    <h4>Privilege Escalation</h4>
                    <h3>{`Number of Threats: 4 `}</h3>
                    <hr className="ThreatHrLine" />
                    <p className="change-text">Just Updated</p>
                </div>
            </div>
        </div>
        <div className='dashboard-container level3'>

            {/* Non-Compliance */}
            <div className="ThreatStats-box" onClick={() => openModal(group13)}>
                <div className="ThreatIcon-box red">
                <GrCompliance />
                </div>
                <div className="ThreatContent-box">
                    <h4>Reconnaissance</h4>
                    <h3>{`Number of Threats: 8 `}</h3>
                    <hr className="ThreatHrLine" />
                    <p className="change-text">Just Updated</p>
                </div>
            </div>
            <div className="ThreatStats-box" onClick={() => openModal(group14)}>
                <div className="ThreatIcon-box green">
                <HiMiniUserGroup />
                </div>
                <div className="ThreatContent-box">
                    <h4>Resource Development </h4>
                    <h3>{`Number of Threats: 13 `}</h3>
                    <hr className="ThreatHrLine" />
                    <p className="change-text">Just Updated</p>
                </div>
            </div>

            {/* Non-Compliance */}
            
        </div>
        {/* Modal for displaying the questions with recommendations */}
        <DashModal isOpen={isDashModalOpen} onClose={closeModal}  groupContent={groupContent}  />
      </div>
    </div>
  );
};

    

export default ThreatIntel;