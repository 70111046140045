import React, { useState, useEffect } from 'react';
import { auth } from '../Firebase_Config/firebase';
import { updatePassword, reauthenticateWithCredential, EmailAuthProvider } from "firebase/auth";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Copyright from '../CopyrightFooter/Copyright';

const AccountManage = () => {
    const [userEmail, setUserEmail] = useState(null);
    const [passwordOne, setPasswordOne] = useState('');
    const [passwordTwo, setPasswordTwo] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [currentPassword, setCurrentPassword] = useState(''); // State for current password input

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                setUserEmail(user.email);
            } else {
                setUserEmail(null);
            }
        });

        return () => unsubscribe();
    }, []);

    const reauthenticateUser = async () => {
        const user = auth.currentUser;
        const credential = EmailAuthProvider.credential(user.email, currentPassword); // Use current password to create credentials
        try {
            await reauthenticateWithCredential(user, credential);
            return true;
        } catch (err) {
            setError('Re-authentication failed. Please check your current password.');
            return false;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setLoading(true);

        if (passwordOne !== passwordTwo) {
            setError('Passwords do not match.');
            setLoading(false);
            return;
        }

        try {
            await updatePassword(auth.currentUser, passwordOne);
            setPasswordOne('');
            setPasswordTwo('');
            setError('Password updated successfully!');
        } catch (error) {
            if (error.code === 'auth/requires-recent-login') {
                // If reauthentication is required, ask for the current password
                const reauthenticated = await reauthenticateUser();
                if (reauthenticated) {
                    // Retry password update after re-authentication
                    await updatePassword(auth.currentUser, passwordOne);
                    setError('Password updated successfully!');
                }
            } else {
                setError(error.message);
            }
        }
        setLoading(false);
    };

    return (
        <div style={{ backgroundColor: 'rgb(240, 242, 245)' }}>
            <div className="accountcontent">
                <div className='ContainerBox'>
                    <div className='accountheader'>
                        <h1 className='accounth1'>Account Management</h1>
                    </div>
                    <div>
                        <h4 className='fontChange'>
                            {'Want to Change your Password?'}
                        </h4>
                        <p className='fontChange'>
                            {'Enter your new password and then repeat. Then click the reset button.'}
                        </p>
                        {userEmail && (
                            <p className='fontChange'>
                                {'Logged in as: ' + userEmail}
                            </p>
                        )}
                        
                        {/* Current password input for re-authentication */}
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="currentPassword"
                            label="Current Password"
                            type="password"
                            id="currentPassword"
                            autoComplete="current-password"
                            onChange={(e) => setCurrentPassword(e.target.value)}
                        />

                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="New Password"
                            type="password"
                            id="password"
                            autoComplete="new-password"
                            error={error ? true : false}
                            onChange={(e) => setPasswordOne(e.target.value)}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="confirmPassword"
                            label="Confirm New Password"
                            type="password"
                            id="confirmPassword"
                            autoComplete="new-password"
                            onChange={(e) => setPasswordTwo(e.target.value)}
                        />
                        <Button
                            onClick={handleSubmit}
                            fullWidth
                            variant="contained"
                            color="primary"
                            style={{ padding: '15px', marginTop: '8px' }}
                            disabled={loading}
                        >
                            {loading ? 'Resetting...' : 'Reset My Password'}
                        </Button>
                        <p className='fontChange'>
                            {'Any problems that you cannot resolve please contact support.'}
                        </p>
                        {error && (
                            <Typography variant="body2" style={{ color: 'red', fontSize: '0.8rem', marginTop: 10 }}>
                                {error}
                            </Typography>
                        )}
                    </div>
                </div>
            </div>
            <Copyright/>
        </div>
    );
};

export default AccountManage;
